<template>
  <v-container fluid class="pa-0">
    <!-- 系统bar-->
    <v-app-bar app color="indigo darken-1" dark dense>
      <!-- 基础工具 普通用户、项目管理员可用、系统管理员 -->
      <v-app-bar-nav-icon @click="
          showMenu = !showMenu;
          showMenu ? (orderPanelLeft = 45) : (orderPanelLeft = 10);
        " v-if="passedOrderId != -1 && role != 'proUser'"></v-app-bar-nav-icon>
      <!-- 基础工具和高级工具切换 专业人员可用 -->
      <v-menu top :offset-x="true" :close-on-content-click="false" :close-on-click="false"
        v-if="passedOrderId != -1 && role == 'proUser'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn fab small class="font-weight-bold" v-bind="attrs" v-on="on" icon @click="
              showToolSelector = !showToolSelector;
              showToolSelector ? (orderPanelLeft = 45) : (orderPanelLeft = 10);
            ">
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>
        <v-card max-width="200" v-if="showToolSelector">
          <v-card-text class="text-center pa-1">
            <v-btn x-small :color="showAdvanceTool ? 'primary' : 'grey'" text @click="
                showAdvanceTool = true;
                showOrdinaryTool = false;
              ">高级工具</v-btn>
            <v-btn x-small :color="showOrdinaryTool ? 'primary' : 'grey'" text @click="
                showOrdinaryTool = true;
                showAdvanceTool = false;
              ">基础工具</v-btn>
          </v-card-text>
        </v-card>
      </v-menu>

      <v-spacer></v-spacer>
      <!-- 系统标题 -->
      <v-toolbar-title class="px-auto">遥感工具平台</v-toolbar-title>

      <v-spacer></v-spacer>
      <!-- 订单面板显示/隐藏按钮 -->
      <v-btn text x-small @click="
          showOrderPanel = !showOrderPanel;
          role == 'proUser' ? isShowOrderData() : '';
        " class="font-weight-bold">项目</v-btn>

      <!-- 文件目录 -->
      <v-menu left :offset-y="true" :close-on-content-click="false" v-if="passedOrderId != -1">
        <template v-slot:activator="{ on, attrs }">
          <v-btn text x-small class="font-weight-bold" v-bind="attrs" v-on="on">
            文件
          </v-btn>
        </template>
        <v-card max-width="500" class="overflow-x-auto overflow-y-auto" max-height="500">
          <!-- 最小宽度要大于文件内容的宽度 不然折叠时会收缩-->
          <v-list dense style="min-width:450px;">
            <!-- 文件上传按钮 -->
            <v-file-input label="上传文件" prepend-icon="" prepend-inner-icon="mdi-file"
              append-icon="mdi-arrow-right-circle" show-size counter multiple truncate-length="20" dense
              class="mx-3 mb-1 mt-2" accept="image/*,.doc,.docx,.pdf" v-if="passedOrderId != -1 && showFileUploadBtn"
              v-model="uploadedFile" :rules="[v => !!v.length || '文件必选']" @click:append="selectedUploadFiles()">
            </v-file-input>
            <v-divider v-if="passedOrderId != -1 && showFileUploadBtn"></v-divider>
            <!-- 生成的遥感图列表 上传的文件列表 -->
            <v-list-group v-for="(value, title, i) in menuFiles" :key="i">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>{{ title }}</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item-group v-model="value[1]" multiple @change="title == '遥感图' ? changeImages(value[1]) : ''">
                <!-- @change="i == 0 ? changeImages(value[1]) : changeUploadedFiles(value[1])" -->
                <v-list-item v-for="(item, index) in value[0]" :key="index">
                  <!-- <template v-slot:default="{ active }"> -->
                  <!-- <v-list-item-action>
                        <v-checkbox :input-value="active"></v-checkbox>
                      </v-list-item-action> -->
                  <v-list-item-icon class="">
                    <!-- <v-img :src="i == 0 ? item.src : fileIconImage[item.type]" height="30" width="30"></v-img> -->
                    <span class="font-weight-bold indigo--text" v-if="title == '报告'">{{ index + 1 }}.</span>
                    <span class="font-weight-bold indigo--text mr-n1" v-else-if="
                        index == 0 ||
                          value[0][index].id != value[0][index - 1].id
                      ">{{ item.id }}</span>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title v-text="item.name"></v-list-item-title>
                    <v-list-item-subtitle class="caption" v-if="title == '报告'">
                      <span class="font-weight-bold caption">时间:</span>{{ item.time }} &nbsp;&nbsp;
                      <span class="font-weight-bold caption">大小:</span>
                      {{ item.size }}
                    </v-list-item-subtitle>
                  </v-list-item-content>
                  <v-list-item-icon>
                    <v-progress-circular color="amber" indeterminate v-if="item.showDownloadLoading">
                    </v-progress-circular>
                  </v-list-item-icon>
                  <v-list-item-action>
                    <v-row>
                      <v-col class="py-0" v-if="title == '报告'">
                        <a :href="downloadUrl + item.url" :download="item.name">
                          <v-btn small color="primary">下载 </v-btn>
                        </a>
                      </v-col>
                      <v-col class="py-0">
                        <v-btn color="error" small @click.stop.prevent="deleteReportFile(item.id)">删除</v-btn>
                      </v-col>
                    </v-row>
                    <!-- <v-btn v-else color="error" small @click.stop.prevent="deleteReportFile(item.id)">删除</v-btn> -->
                  </v-list-item-action>
                  <!-- </template> -->
                </v-list-item>
              </v-list-item-group>
              <div class="d-flex justify-center mt-2 mb-2" v-if="title == '报告'">
                <!-- <v-btn class="primary" small @click="downloadFiles()">下载</v-btn> -->
              </div>
              <v-divider></v-divider>
            </v-list-group>
          </v-list>
        </v-card>
      </v-menu>

      <!-- 任务列表按钮 -->
      <v-btn text x-small @click="isShowTaskList = !isShowTaskList" class="font-weight-bold"
        v-if="passedOrderId != -1 && role == 'proUser'">任务</v-btn>

      <!-- 回到主页按钮 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-small @click="backMain(0)" class="mx-1" v-bind="attrs" v-on="on">
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </template>
        <span>主页</span>
      </v-tooltip>
      <!-- 回到个人资料按钮 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-small @click="backMain(1)" class="mx-2" v-bind="attrs" v-on="on">
            <v-icon>mdi-account-details</v-icon>
          </v-btn>
        </template>
        <span>个人资料</span>
      </v-tooltip>
      <!-- 回到系统消息按钮 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-small @click="backMain(2)" class="mx-1" v-bind="attrs" v-on="on">
            <v-icon>mdi-bell</v-icon>
          </v-btn>
        </template>
        <span>系统消息</span>
      </v-tooltip>
      <!-- 回到用户管理按钮 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-small @click="backMain(3)" class="mx-2" v-if="role == 'projectAdmin' || role == 'sysAdmin'"
            v-bind="attrs" v-on="on">
            <v-icon>mdi-account-supervisor</v-icon>
          </v-btn>
        </template>
        <span>用户管理</span>
      </v-tooltip>
      <!-- 回到工具管理按钮 -->
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon x-small @click="backMain(5)" class="mx-1" v-if="role == 'proUser' || role == 'sysAdmin'"
            v-bind="attrs" v-on="on">
            <v-icon>mdi-toolbox</v-icon>
          </v-btn>
        </template>
        <span>工具管理</span>
      </v-tooltip>
      <!-- 登出 -->
      <v-btn icon fab small @click="logout()">
        <v-icon size="18">mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>
    <!-- 地图组件 -->

    <div id="map-container" style="min-height: 95vh"></div>
    <!-- 普通工具栏 -->
    <v-card style="position:absolute;left:0;top:0;min-height:95.4vh" color="grey darken-2" v-if="showMenu">
      <v-list color="grey darken-2">
        <v-list-item v-for="(item, index) in toolList" :key="index" class="px-2">
          <v-tooltip right nudge-left="">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="item.color" :disabled="item.disable" @click="tools(item.name)">
                {{ item.icon }}</v-icon>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-card>
    <!-- 普通/高级工具栏 -->
    <v-card style="position:absolute;left:0;top:0;min-height:95.4vh" color="grey darken-2" class="text-end"
      v-if="showToolSelector">
      <!-- <v-btn icon x-small color="white" v-if="showOrdinaryTool || showAdvanceTool" @click="showOrdinaryTool=false;showAdvanceTool=false;orderPanelLeft = 10"><v-icon>mdi-close-circle-outline</v-icon></v-btn> -->
      <v-list color="grey darken-2" class="" v-show="showOrdinaryTool">
        <v-list-item v-for="(item, index) in toolList" :key="index" class="px-2">
          <v-tooltip right nudge-left="">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" :color="item.color" :disabled="item.disable" @click="tools(item.name)">
                {{ item.icon }}</v-icon>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
      <v-list color="grey darken-2" class="" v-show="showAdvanceTool">
        <v-list-item v-for="(item, index) in advanceToolList" :key="index" class="px-2">
          <v-tooltip right nudge-left="">
            <template v-slot:activator="{ on, attrs }">
              <v-icon v-bind="attrs" v-on="on" color="white" @click="
                  isShowParamDialog = true;
                  showParamDialog(item.name, item.info);
                ">{{ item.icon }}</v-icon>
            </template>
            <span>{{ item.name }}</span>
          </v-tooltip>
        </v-list-item>
      </v-list>
    </v-card>
    <!-- 订单信息 -->
    <div class="order-panel-style" :style="{ left: orderPanelLeft + 'px' }" id="order-panel" v-show="showOrderPanel">
      <v-expansion-panels focusable hover :value="0" :style="{
          'max-width': role == 'customer' ? '450px' : '400px',
          'max-height': role == 'customer' ? '810px' : '',
          'overflow-y': role == 'customer' ? 'auto' : ''
        }" :class="role == 'customer' ? 'elevation-3' : ''">
        <v-expansion-panel>
          <div id="order-panel-header" style="cursor:move!important">
            <v-expansion-panel-header class="font-weight-bold">
              项目信息
            </v-expansion-panel-header>
          </div>
          <!-- 订单内容 -->
          <v-expansion-panel-content>
            <!-- 客户端添加步骤提示 -->
            <v-stepper vertical class="elevation-0" v-if="role == 'customer'">
              <v-stepper-step step="1" :complete="orderStatusIndex >= 1">详情</v-stepper-step>
              <v-stepper-content step="1" class="pa-0 pl-4 mr-0">
                <orderInfo :passedOrderId="passedOrderId" :leafMap.sync="leafMap" ref="orderInfoChild"
                  :conditionalSearchRadioGroups.sync="
                    conditionalSearchRadioGroups
                  " v-on:passMsgBtnBottom="getmMsgBtnBottom" v-on:passFileUploadPermission="getFileUploadPermission"
                  :scenesSelectedArray="scenesSelectedArray" :raster.sync="raster" @tips="tips"
                  @changeSatType="changeSatType" @setScenesSelectedArray="setScenesSelectedArray">
                </orderInfo>
              </v-stepper-content>

              <v-stepper-step step="2" :complete="orderStatusIndex >= 2">审批<small class="mt-1">项目创建完成后由项目管理员进行审批</small>
              </v-stepper-step>
              <v-stepper-step step="3" :complete="orderStatusIndex >= 3">报价<small class="mt-1">项目管理员审批完成后进行报价</small>
              </v-stepper-step>
              <v-stepper-step step="4" :complete="orderStatusIndex >= 4">签约<small class="mt-1">客户确认合同后进行签约</small>
              </v-stepper-step>
              <v-stepper-step step="5" :complete="orderStatusIndex >= 5">付款<small class="mt-1">客户签约后付款</small>
              </v-stepper-step>
              <v-stepper-step step="6" :complete="orderStatusIndex >= 6">到账，处理中<small
                  class="mt-1">项目管理员确认到账后，系统自动分配专家处理项目</small></v-stepper-step>
              <v-stepper-step step="7" :complete="orderStatusIndex >= 7">完成<small class="mt-1">专家处理完项目后标记项目为已完成</small>
              </v-stepper-step>
            </v-stepper>
            <!-- 非客户端 -->
            <orderInfo v-else :passedOrderId="passedOrderId" :leafMap.sync="leafMap"
              :conditionalSearchRadioGroups.sync="conditionalSearchRadioGroups" v-on:passMsgBtnBottom="getmMsgBtnBottom"
              v-on:passFileUploadPermission="getFileUploadPermission" :scenesSelectedArray="scenesSelectedArray"
              :raster.sync="raster" @tips="tips" @changeSatType="changeSatType"
              @setScenesSelectedArray="setScenesSelectedArray">
            </orderInfo>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>
    <!-- 项目消息 -->
    <div class="msg-btn-style" :style="{ bottom: msgBtnBottom + 'px' }" v-if="passedOrderId != -1">
      <v-btn color="pink lighten-2" small dark fab @click="showNotice = !showNotice">
        <v-icon>mdi-comment-processing-outline</v-icon>
      </v-btn>
    </div>
    <div class="msg-panel-style" :style="{ bottom: msgBtnBottom + 'px' }" v-if="showNotice">
      <v-expansion-panels focusable :value="0" style="max-width:400px">
        <v-expansion-panel>
          <v-expansion-panel-header class="font-weight-bold">项目消息</v-expansion-panel-header>
          <v-expansion-panel-content class="pt-2" style="max-height:300px;">
            <v-row no-gutters dense>
              <v-col cols="12" sm="1">
                <v-avatar size="28" color="indigo">
                  <span class="white--text headline"> {{ avatarName }} </span>
                </v-avatar>
              </v-col>
              <v-col cols="12" sm="10">
                <v-row dense no-gutters>
                  <v-col cols="12">
                    <v-textarea label="评论" no-resize rows="2" outlined class="ml-2" dense v-model="messageContent">
                    </v-textarea>
                  </v-col>
                  <v-col cols="2" offset="10" class="mt-n5">
                    <v-btn class="primary" x-small @click="submitMessage()">提交</v-btn>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <div class="d-flex flex-column-reverse">
              <div v-for="(item, i) in messages" :key="i">
                <div v-if="item.sender != username">
                  <v-row dense no-gutters>
                    <v-col cols="12" sm="1">
                      <v-avatar size="28" color="indigo">
                        <span class="white--text headline">
                          {{ item.avatarName }}
                        </span>
                      </v-avatar>
                    </v-col>
                    <v-col cols="12" sm="11">
                      <v-col cols="12" class="">
                        <span class="caption primary--text">{{
                          item.sender
                        }}</span>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <v-card elevation="1" class="pa-1" color="green lighten-5">
                          <span class="caption">{{ item.messageContent }}</span>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12">
                        <span class="" style="font-size:11px">{{
                          item.sendTimeStr
                        }}</span>
                      </v-col>
                    </v-col>
                  </v-row>
                </div>
                <div v-else>
                  <v-row dense no-gutters class="">
                    <v-col cols="12" sm="11">
                      <v-col cols="12" class="text-end">
                        <span class="caption primary--text">{{
                          item.sender
                        }}</span>
                      </v-col>
                      <v-col cols="12" sm="12" class="text-end">
                        <v-card elevation="1" class="pa-1" color="green lighten-5">
                          <span class="caption">{{ item.messageContent }}</span>
                        </v-card>
                      </v-col>
                      <v-col cols="12" sm="12" class="text-end">
                        <span class="" style="font-size:11px">{{
                          item.sendTimeStr
                        }}</span>
                      </v-col>
                    </v-col>
                    <v-col cols="12" sm="1" class="ml-n1 mt-1">
                      <v-avatar size="28" color="indigo">
                        <span class="white--text headline">
                          {{ item.avatarName }}
                        </span>
                      </v-avatar>
                    </v-col>
                  </v-row>
                </div>
              </div>
            </div>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </div>

    <!-- 图层设置 -->
    <div class="layer-bar-style" v-if="passedOrderId != -1">
      <v-card>
        <v-card-title class="pa-1 pl-2">
          <v-img src="../../assets/scenes/imagery-show-1x.png" min-width="20" min-height="10"></v-img>
          <v-btn icon small class="ml-2" @click="showLayer = !showLayer">
            <v-icon>{{
              showLayer == true
                ? "mdi-chevron-double-down"
                : "mdi-chevron-double-up"
            }}</v-icon>
          </v-btn>
        </v-card-title>
      </v-card>
    </div>
    <v-bottom-sheet inset v-model="showLayer" overlay-opacity="0">
      <v-sheet max-height="200" class="px-2" style="background-color:aliceblue">
        <div style="overflow:auto hidden;white-space:nowrap;" class="py-4">
          <div v-for="(item, i) in imageList" :key="i" style="display: inline-block;vertical-align:top">
            <v-card v-if="item['imageLayer']" height="150" width="300" class="mx-2" elevation="6">
              <v-card-subtitle class="text-center white--text blue-grey">{{
                item.name
              }}</v-card-subtitle>
              <v-card-text class="mt-8">
                <v-slider v-model="item.opacity" thumb-label="always" thumb-size="20" min="0" max="1" step="0.1"
                  inverse-label label="透明度" @change="changeLayerOpacity(item['imageLayer'], item.opacity)"></v-slider>
              </v-card-text>
            </v-card>
          </div>
        </div>
      </v-sheet>
      <div style="height:20px;background-color: #FFFFFF;"></div>
    </v-bottom-sheet>

    <!-- 卫星地图 -->
    <div class="satellite-map-switch" :style="{ left: orderPanelLeft + 'px' }">
      <v-card max-width="80" max-height="80" @click="
          showStreetLayer = !showStreetLayer;
          switchLayer();
        " :color="showStreetLayer ? 'white' : 'black'">
        <v-card-title style="padding:1px 2px">
          <v-img src="../../assets/scenes/satellite_map.png" max-height="75" max-width="75" class="rounded align-end"
            v-if="showStreetLayer == true">
            <span class="white--text caption ml-1">Satellite</span>
          </v-img>
          <v-img src="../../assets/scenes/street_map.png" max-height="75" max-width="75" class="rounded align-end"
            v-else>
            <span class="white--text caption ml-1">Map</span>
          </v-img>
        </v-card-title>
      </v-card>
    </div>

    <!-- 操作参数对话框 -->
    <v-dialog v-model="isShowParamDialog" max-width="30vw">
      <v-card>
        <v-card-title>
          {{ paramDiglogTitle }}
        </v-card-title>
        <v-card-subtitle class="pb-0">
          {{ toolsInfo }}
          <v-row no-gutters dense>
            <v-col cols="12" class="">
              <v-radio-group row v-model="selectedFileIndex" dense>
                <v-radio v-for="(type, index) in selectedFileType" :key="index" :label="type.text" :value="index"
                  :disabled="type.disable"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-card-subtitle>
        <v-card-text>
          <p class="font-weight-bold">选中的文件列表:</p>
          <div>
            <!-- 原文件 -->
            <div v-if="selectedFileIndex == 0">
              <v-row v-for="(item, index) in scenesSelectedArray" :key="index" class="mb-1">
                <v-col cols="12" md="1" class="d-flex align-center">
                  <v-btn fab dark x-small color="light-green accent-4" width="20px" height="20px"
                    @click="cancelSelectedOriginScene(item)">
                    <v-icon dark>mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <v-col cols="12" md="11">
                  {{ item.png_path }}
                </v-col>
              </v-row>
            </div>
            <!-- 已完成图片 -->
            <div v-else-if="selectedFileIndex == 1 && menuFiles['遥感图']">
              <v-row v-for="(item, index) in menuFiles['遥感图'][1]" :key="index" class="mb-1">
                <v-col cols="12" md="11">
                  {{ imageList[item].png_path }}
                </v-col>
              </v-row>
            </div>
          </div>
          <p class="font-weight-bold mt-2">参数列表:</p>
          <!-- 影像融合 -->
          <div v-if="paramDiglogTitle === advanceToolList[0].name">
            <v-form ref="bundleToPerfectSensorForm">
              <v-text-field type="number" label="PAN文件X方向投影图像大小" dense v-model="bundleToPerfectSensorForm.panX"
                :rules="rule('PAN文件X方向投影图像大小 ')" v-if="selectedFileIndex == 0"></v-text-field>
              <v-text-field type="number" label="PAN文件Y方向投影图像大小" dense v-model="bundleToPerfectSensorForm.panY"
                :rules="rule('PAN文件Y方向投影图像大小 ')" v-if="selectedFileIndex == 0"></v-text-field>
              <v-text-field label="PAN文件输出文件名" dense v-model="bundleToPerfectSensorForm.panOutputPath"
                :rules="btpFormPanRule()" v-if="selectedFileIndex == 0"></v-text-field>
              <v-text-field type="number" label="MSS文件X方向投影图像大小" dense v-model="bundleToPerfectSensorForm.mssX"
                :rules="rule('MSS文件X方向投影图像大小 ')" v-if="selectedFileIndex == 0"></v-text-field>
              <v-text-field type="number" label="MSS文件Y方向投影图像大小" dense v-model="bundleToPerfectSensorForm.mssY"
                :rules="rule('MSS文件Y方向投影图像大小 ')" v-if="selectedFileIndex == 0"></v-text-field>
              <v-text-field label="MSS文件输出文件名" dense v-model="bundleToPerfectSensorForm.mssOutputPath"
                :rules="btpFormMssRule()" v-if="selectedFileIndex == 0"></v-text-field>
              <v-text-field label="影像融合输出文件名" dense v-model="bundleToPerfectSensorForm.bundleOutputPath"
                :rules="bundleRuleForm('影像融合')"></v-text-field>
            </v-form>
          </div>
          <!-- 镶嵌 -->
          <div v-if="paramDiglogTitle === advanceToolList[3].name">
            <v-form ref="mergeForms">
              <v-text-field label="波段值" dense v-model="mergeForms.band" :rules="rule('输出波段值')"></v-text-field>
              <v-text-field label="植被覆盖度输出文件名" dense v-model="mergeForms.outputPath" :rules="rule('输出文件名')">
              </v-text-field>
            </v-form>
          </div>
          <!-- 植被覆盖度 -->
          <div v-if="paramDiglogTitle === advanceToolList[2].name">
            <v-form ref="coverForms">
              <v-text-field label="植被覆盖度输出文件名" dense v-model="coverForms.outputPath" :rules="rule('输出文件名')">
              </v-text-field>
            </v-form>
          </div>
          <!--波段运算 -->
          <div v-else-if="paramDiglogTitle === advanceToolList[1].name">
            <!-- <v-form ref="bandMathForm">
              <v-text-field
                label="波段运算输出文件名"
                dense
                v-model="bandMathForm.outputPath"
                :rules="bundleRuleForm('波段运算')"
              ></v-text-field>
              <v-text-field label="运算公式" dense v-model="bandMathForm.expression" readonly></v-text-field>
            </v-form> -->
            <v-form ref="bandMathForms">
              <div v-if="selectedFileIndex == 0 && satType == 'GF2'">
                <p class="primary--text font-weight-bold caption">正射校正</p>
                <div v-for="(ortVal, ortKey, ortIndex) in bandMathForms['ort']" :key="ortIndex">
                  <p class="indigo--text font-weight-bold">
                    {{ ortIndex + 1 }}.
                  </p>
                  <v-text-field type="number" label="X方向投影图像大小" dense v-model="ortVal['x']" :rules="rule('X方向投影图像大小 ')"
                    v-if="selectedFileIndex == 0"></v-text-field>
                  <v-text-field type="number" label="Y方向投影图像大小" dense v-model="ortVal['y']" :rules="rule('Y方向投影图像大小 ')"
                    v-if="selectedFileIndex == 0"></v-text-field>
                  <v-text-field label="正射校正输出文件名" dense v-model="ortVal['outputDataPath']"
                    :rules="bandMathFormMssRule(ortVal)"></v-text-field>
                </div>
              </div>
              <p class="primary--text font-weight-bold caption" v-if="selectedFileIndex == 0">
                波段运算
              </p>
              <v-text-field label="波段运算输出文件名" dense v-model="bandMathForms['bm'].outputPath"
                :rules="bundleRuleForm('波段运算')"></v-text-field>
              <v-text-field label="运算公式" dense v-model="bandMathForms['bm'].expression"></v-text-field>
            </v-form>
          </div>
          <!-- 建筑指数 -->
          <div v-if="paramDiglogTitle === advanceToolList[4].name">
            <v-form ref="buildingForms">
              <v-text-field label="建筑指数图" dense v-model="buildingForms.outputPath1" :rules="rule('输出文件名')">
              </v-text-field>
              <v-text-field label="分割提取二值图" dense v-model="buildingForms.outputPath2" :rules="rule('输出文件名')">
              </v-text-field>
              <v-text-field label="s_min" dense v-model="buildingForms.s_min" :rules="rule('s_min')">
              </v-text-field>
              <v-text-field label="s_max" dense v-model="buildingForms.s_max" :rules="rule('s_max')">
              </v-text-field>
              <v-text-field label="delta_s" dense v-model="buildingForms.delta_s" :rules="rule('delta_s')">
              </v-text-field>
            </v-form>
          </div>
          <!-- 直方图均衡化 -->
          <div v-if="paramDiglogTitle === advanceToolList[5].name">
            <v-form ref="histogramForms">
              <v-text-field label="输出文件名" dense v-model="histogramForms.outputPath" :rules="rule('输出文件名')">
              </v-text-field>
            </v-form>
          </div>
          <!-- 百分比拉伸 -->
          <div v-if="paramDiglogTitle === advanceToolList[6].name">
            <v-form ref="linearForms">
              <v-text-field label="输出文件名" dense v-model="linearForms.outputPath" :rules="rule('输出文件名')">
              </v-text-field>
              <v-text-field label="拉伸百分比" dense v-model="linearForms.percentage" :rules="rule('输出文件名')">
              </v-text-field>
            </v-form>
          </div>
          <!-- 腐蚀与膨胀 -->
          <div v-if="paramDiglogTitle === advanceToolList[7].name">
            <v-form ref="corrosionForms">
              <v-text-field label="膨胀特征结果" dense v-model="corrosionForms.outputPath1" :rules="rule('输出文件名')">
              </v-text-field>
              <v-text-field label="腐蚀特征结果" dense v-model="corrosionForms.outputPath2" :rules="rule('输出文件名')">
              </v-text-field>
              <v-text-field label="OTSU膨胀优化结果" dense v-model="corrosionForms.outputPath3" :rules="rule('输出文件名')">
              </v-text-field>
              <v-text-field label="OTSU腐蚀优化结果" dense v-model="corrosionForms.outputPath4" :rules="rule('输出文件名')">
              </v-text-field>
            </v-form>
          </div>
          <!-- 变化检测 -->
          <div v-if="paramDiglogTitle === advanceToolList[8].name">
            <v-form ref="changeForms">
              <v-text-field label="输出文件名" dense v-model="changeForms.outputPath" :rules="rule('输出文件名')">
              </v-text-field>
            </v-form>
          </div>
          <!-- 雷达干涉 -->
          <div v-if="paramDiglogTitle === advanceToolList[9].name">
            <v-form ref="radarForms">
              <v-text-field label="输出文件名" dense v-model="radarForms.outputPath" :rules="rule('输出文件名')">
              </v-text-field>
            </v-form>
          </div>
          <!-- 道路提取 -->
          <div v-if="paramDiglogTitle === advanceToolList[10].name">
            <v-form ref="roadForms">
              <!-- <v-text-field label="提取范围" dense v-model="radarForms.outputPath" :rules="rule('提取范围')">
              </v-text-field> -->
              <v-input append-icon="mdi-select-multiple" @click:append="get_bounds">
                提取范围
              </v-input>
              <v-img :src="imgBounds"></v-img>
              <v-text-field class="py-2" label="输出文件名" dense v-model="roadForms.outputPath" :rules="rule('输出文件名')">
              </v-text-field>
            </v-form>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="isShowParamDialog = false">取消</v-btn>
          <v-btn color="primary" @click="paramSubmit()">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- 任务列表 -->
    <div style="position:absolute;top:0;right:0;" v-show="isShowTaskList" class="mt-2 mr-2">
      <v-card max-height="75vh" class="pa-2 overflow-y-auto" min-width="500">
        <v-card-title> 任务列表 </v-card-title>
        <div v-for="(val, key, index) in taskList" :key="index">
          <v-card-subtitle v-if="val.length > 0" class="pt-0">{{ key == "waiting" ? "等待队列" : "运行队列"
            }}<v-btn v-if="key == 'waiting'" small class="ml-2" color="primary" @click="orderWaitingTasks()">排序</v-btn>
          </v-card-subtitle>
          <draggable :list="val" :disabled="key == 'notWaiting'" class="list-group" :move="checkMove"
            @start="dragging = true" @end="dragging = false">
            <v-card-text v-for="(item, toolIndex) in val" :key="toolIndex" class="pt-0" :id="item.id">
              <v-card>
                <v-card-text>
                  <v-row align="center">
                    <v-col cols="12" sm="1">
                      <v-avatar color="indigo" size="28"><span class="white--text">{{
                          item.id
                        }}</span></v-avatar>
                    </v-col>
                    <v-col cols="12" sm="11">
                      {{ item.imageName }}
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12" sm="1"></v-col>
                    <v-col cols="12" sm="2" class="d-flex align-center">
                      <v-icon color="primary darken-1" size="20" @click="item.showSubProcess = !item.showSubProcess">{{
                          item.showSubProcess == false
                            ? "mdi-chevron-down-circle-outline"
                            : "mdi-chevron-up-circle-outline"
                        }}</v-icon>
                      <span class="caption">详情</span>
                    </v-col>
                    <v-col cols="12" sm="3" class="brown--text">{{
                      item.operation
                    }}</v-col>
                    <v-col cols="12" sm="3" class="teal--text font-italic">
                      {{ taskStatusFormat(item.status) }}
                    </v-col>
                    <v-col cols="12" sm="3" class="text-end">
                      <v-btn small color="warning" class="ml-1" :loading="item.btnLoading"
                        :disabled="item.status == 'end' || item.btnDisable" @click="
                          item.isPauseBtn = !item.isPauseBtn;
                          pauseOrRestore(item);
                        ">{{ item.isPauseBtn == true ? "暂停" : "恢复" }}</v-btn>
                    </v-col>
                  </v-row>
                  <v-row align="center">
                    <v-col cols="12" sm="1"> </v-col>
                    <v-col cols="12" sm="11">
                      <v-expand-transition>
                        <v-card v-show="item.showSubProcess" class="mt-2">
                          <v-card-subtitle class="caption">子任务列表</v-card-subtitle>
                          <v-card-text>
                            <div v-for="(childItem, index) in item.childProcesses" :key="index">
                              <v-row align="center" class="caption mb-1">
                                <v-col cols="12" sm="1" class="d-flex align-center">
                                  {{ index + 1 }}.
                                </v-col>
                                <v-col cols="12" sm="6">
                                  命令:
                                  <span class="primary--text">{{
                                    childItem.processName
                                  }}</span>
                                </v-col>
                                <v-col cols="12" sm="5">
                                  任务:
                                  <span class="cyan--text">{{
                                    taskStatusFormat(childItem.status)
                                  }}</span>
                                </v-col>
                                <!-- <v-col
                                  cols="12"
                                  sm="3"
                                  class="d-flex align-top"
                                >
                                  切片:
                                  <span class="purple--text">{{childItem.tilesStatus != 'end' ? '未完成' : '已完成'}}</span>
                                  <v-btn
                                    v-else
                                    x-small
                                    color="grey"
                                    @click="
                                      showTileLayer(
                                        'childTile',
                                        childItem,
                                        index
                                      )
                                    "
                                    >刷新显示</v-btn
                                  >
                                </v-col> -->
                              </v-row>
                            </div>
                          </v-card-text>
                        </v-card>
                      </v-expand-transition>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-card-text>
          </draggable>
          <div :id="key"></div>
        </div>
      </v-card>
    </div>
    <!-- 等待界面 -->
    <v-dialog v-model="loading" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          影像处理中
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- 通知工具 -->
    <v-snackbar v-model="showSnackbar" :color="snackbarColor" :timeout="2000" :top="true">
      {{ snackbarText }}
    </v-snackbar>
  </v-container>
</template>
<script>
import L from "leaflet"; //leaflet地图obj
//import "leaflet-draw/dist/leaflet.draw";
import axios from "axios"; //基于Promise的HTTP客户端
import qs from "qs";
//
import mapCfg from "../../config/map";
import commonCfg from "../../config/common";
import Jimp from "jimp/es";
import draggable from "vuedraggable";
import orderInfo from "./orderInfo.vue";
// let leafMap; // 地图实例
// let raster = {
//   latLngs: [],
//   layer: ""
// };
let Url = commonCfg.commonUrl,
  version = commonCfg.urlVersion; // http://192.168.1.109:64011/
let vm = null,
  streetMapLayer = null,
  mapOptions = {},
  satelliteMapLayer = null,
  // coordPolygon = null,
  //username = localStorage.getItem("username"),//有缓存
  processInterval = null; //定时器
let wsConn;
//专业人员 动画处理
// import gsap from "gsap/gsap-core";
// import TweenLite from "gsap/all";
// import Draggable from "gsap/Draggable";
// import { tr } from "date-fns/locale";
// gsap.registerPlugin(Draggable);

//import DialogDrag from 'vue-dialog-drag'
export default {
  data () {
    return {
      enabled: true,
      leafMap: "",
      raster: {
        latLngs: [],
        layer: ""
      },
      downloadDisable: false,
      dragging: false,
      downloadUrl: "https://remotesensing.live/scenes/",
      png: "",
      restorePng: "",
      click_img: "",
      btnLoading: false, // 暂停/恢复 加载控制
      btnDisable: false, // 禁用暂停/恢复按钮
      imgStack: [],
      boundsStack: [],
      processStack: [],
      sizeStack: [],
      stopRectArea: null,
      loading: false,
      //tips
      showSnackbar: false,
      snackbarColor: "error",
      snackbarText: "",
      conditionalSearchRadioGroups: ["行政区", "经纬度"], //类型
      //对订单操作生成的文件列表
      fileList: [],
      //普通工具列表
      //高级工具列表
      advanceToolList: [
        {
          icon: "mdi-set-center",
          info:
            "将在空间、时间、波谱上冗余或互补的多源遥感数据按照一定的规则（或算法）进行运算处理，获得比任何单一数据更精确、更丰富的信息，生成具有新的空间、波谱、时间特征的合成影像数据。将多光谱影像与全色影像融合既提高了多光谱影像的空间分辨率，又可以保留其多光谱特性",
          name: "影像融合"
        },
        {
          icon: "mdi-bandage",
          info: "对多个波段的每个像素点数值进行数学运算。",
          name: "波段运算"
        },
        {
          icon: "mdi-sprout",
          info: "",
          name: "植被覆盖度"
        },
        {
          icon: "mdi-merge",
          info:
            "将两幅或多幅影像拼在一起，构成一幅整体影像，同时尽量保持图像的连续性。",
          name: "镶嵌"
        },
        {
          icon: "mdi-office-building",
          info:
            "通过中红外和近红外的差与和之比反映建筑用地信息，数值越大表明建筑用地比例越高, 建筑密度越高。",
          name: "建筑指数"
        },
        {
          icon: "mdi-chart-histogram",
          info:
            "通过改变图像的直方图来改变图像中各像素的灰度，主要用于增强动态范围偏小的图像的对比度。对在图像中像素个数多的灰度值（即对画面起主要作用的灰度值）进行展宽，而对像素个数少的灰度值（即对画面不起主要作用的灰度值）进行归并，从而增大对比度，使图像清晰，达到增强的目的。",
          name: "直方图均衡化"
        },
        {
          icon: "mdi-stretch-to-page",
          info:
            "将原图像的像素的最大值和最小值指定为设定的百分比位置的像素值，然后线性拉伸到设定的范围。对于整体偏暗的原始图像，拉伸之后的图像视觉效果可以得到增强。",
          name: "百分比拉伸"
        },
        {
          icon: "mdi-office-building",
          info:
            "腐蚀与膨胀是数字形态学里的两个基本操作。膨胀将目标点融合到背景中，向外部扩展。膨胀处理可以将断裂开的目标物进行合并，便于对其整体的提取。腐蚀与膨胀意义相反，消除连通的边界，使边界向内收缩。腐蚀处理可以将粘连在一起的不同目标物分离，并可以将小的颗粒噪声去除。",
          name: "膨胀与腐蚀"
        },
        {
          icon: "mdi-compare",
          info:
            "利用差值法进行变化监测。图像中未发生变化的地物在两个时相的遥感图像上一般具有相等或相近的灰度值，而当地物发生变化时，对应位置的像素值将有较大的差别。",
          name: "变化监测"
        },
        {
          icon: "mdi-radar",
          info:
            "利用雷达向目标区域发射微波，然后接收目标反射的回波，得到同一目标区域成像的SAR复图像对，若复图像对之间存在相干条件，SAR复图像对共轭相乘可以得到干涉图，根据干涉图的相位值，得出两次成像中微波的路程差，从而计算出目标地区的地形、地貌以及表面的微小变化，可用于数字高程模型建立、地壳形变探测等。",
          name: "雷达干涉"
        },
        {
          icon: "mdi-road-variant",
          info:
            "使用训练好的D-LinkNet模型提取输入图像的道路。结果中白色部分为道路。",
          name: "道路提取"
        }
      ],
      imgBounds: "",
      showMenu: false, //是否显示普通工具栏
      showToolSelector: false, //是否显示普通/高级工具选择按钮组
      showAdvanceTool: true, //是否显示高级工具
      showOrdinaryTool: false, //是否显示普通工具
      showNotice: false, //是否显示项目消息
      messageContent: "", //消息内容
      messages: [
        // //项目消息列表
      ],
      toolList: [
        {
          icon: "mdi-content-save",
          name: "save",
          color: "green",
          disable: false
        },
        {
          icon: "mdi-crop-rotate",
          name: "rotate",
          color: "white",
          disable: false
        },

        {
          icon: "mdi-content-cut",
          name: "cut",
          color: "white",
          disable: false
        },

        {
          icon: "mdi-restore",
          name: "restore",
          color: "white",
          disable: false
        },
        {
          icon: "mdi-undo-variant",
          name: "back",
          color: "white",
          disable: false
        },
        {
          icon: "mdi-angle-acute",
          name: "acute",
          color: "white",
          disable: true
        },
        {
          icon: "mdi-pencil-outline",
          name: "pencil",
          color: "white",
          disable: true
        },
        {
          icon: "mdi-eye",
          name: "eye",
          color: "white",
          disable: true
        }
      ],
      showLayer: false, //是否显示图层

      orderPanelLeft: 10, //订单面板距页面左侧的距离
      msgBtnBottom: 0, //消息按钮距底部的距离
      showStreetLayer: true, //显示街道地图还是卫星图 false时显示卫星地图
      selectedFiles: [], //选中的文件数组
      selectedImages: [], //选中的遥感图数组
      uploadedFile: [], //上传的文件
      imageList: [],
      selectedFileType: [
        {
          text: "原文件",
          disable: false
        },
        {
          text: "处理后",
          disable: false
        }
      ], //工具参数文件类型原文件还是处理后的
      selectedFileIndex: 0, //工具参数文件类型对应的下标
      //menuFiles: {},//菜单文件列表 包括遥感图和报告
      role: "", //角色

      passedOrderId: -1, //从主页传递的订单id -1代表新建
      showFileUploadBtn: false, //是否显示文件上传按钮
      // sameSatType: true,
      satType: "",
      scenesSelectedArray: [], //从原scene列表里选中的scene数组
      isShowParamDialog: false, //显示参数对话框
      paramDiglogTitle: "", //对话框标题名
      toolsInfo: "", //工具简介
      //正射校正表单
      // orthoRectificationForm: {
      //   x: "",
      //   y: ""
      // },
      //影像融合
      bundleToPerfectSensorForm: {
        panX: "",
        panY: "",
        panOutputPath: "",
        mssX: "",
        mssY: "",
        mssOutputPath: "",
        bundleOutputPath: ""
      },
      coverForms: {
        outputPath: ""
      },
      mergeForms: {
        outputPath: "",
        band: ""
      },
      buildingForms: {
        outputPath1: "",
        outputPath2: "",
        s_min: 3,
        s_max: 20,
        delta_s: 1
      },
      histogramForms: {
        outputPath: ""
      },
      linearForms: {
        outputPath: "",
        percentage: ""
      },
      changeForms: {
        outputPath: ""
      },
      radarForms: {
        outputPath: ""
      },
      corrosionForms: {
        outputPath1: "",
        outputPath2: "",
        outputPath3: "",
        outputPath4: ""
      },
      roadForms: {
        inputPath: "",
        start_point: "",
        end_point: "",
        image_start: "",
        image_end: "",
        outputPath: ""
      },
      // road_hidden: false,
      // bandMathForm:{
      //   outputPath: '',
      //   expression: "(im1b1-im1b2)/(im1b1+im1b2)"
      // },
      isShowTaskList: false, //显示任务列表面板
      taskList: { waiting: [], notWaiting: [] }, //任务列表对象
      showOrderPanel: true, // 是否显示订单信息面板 专业人员处理时还应判断scene图片的显示/隐藏
      orderStatusIndex: 0, //订单状态index 便于判断进行到哪一步
      orderStatusArray: [
        "new",
        "approval",
        "offered",
        "sign",
        "payed",
        "arrived",
        "finished"
      ] //订单状态数组
    };
  },
  components: {
    draggable,
    orderInfo
  },
  computed: {
    //菜单文件列表 包括遥感图和报告
    menuFiles: function () {
      let menuFileObj = {};
      if (this.fileList.length > 0) {
        menuFileObj["报告"] = [this.fileList, this.selectedFiles];
      }
      if (this.imageList.length > 0) {
        menuFileObj["遥感图"] = [this.imageList, this.selectedImages];
      }
      return menuFileObj;
    },
    // disable: function () {
    //   console.log(this.imgStack);
    //   return this.imgStack.length < 1;
    // },
    avatarName: function () {
      return localStorage.getItem("username").substring(0, 2); //头像名
    },
    username: function () {
      return localStorage.getItem("username");
    },
    //原文件波段运算时根据传入的文件个数动态构建正射参数
    bandMathForms: function () {
      let form = { ort: {} };
      //正射校正参数构建
      for (let i = 0; i < this.scenesSelectedArray.length; i++) {
        console.log(this.scenesSelectedArray[i].sat_id);

        if (this.satType == "GF2") {
          form["ort"][`ort${i + 1}`] = {
            x: "",
            y: "",
            outputDataPath: "",
            inputDataPath: `${this.scenesSelectedArray[i].png_path.split("-")[0]
              }/${this.scenesSelectedArray[i].png_path + ".tiff"}`,
            demDir: "srtm_wh.tif",
            band: "3,2,1",
            percentile: "2,98",
            version: "latest"
          };
        }
      }
      //波段运算参数
      form["bm"] = {
        outputPath: "",
        expression: "(im1b1-im1b2)/(im1b1+im1b2)"
      };
      return form;
    }
  },
  destroyed () {
    //销毁定时器
    if (processInterval) {
      clearInterval(processInterval);
      processInterval = null;
    }
    if (wsConn) {
      wsConn.onclose = undefined;
      wsConn.close(1000); //参数1000 代表正常关闭
    }
  },
  beforeRouteLeave: function (to, from, next) {
    console.log(this.processStack);
    if (this.processStack.length > 0) {
      this.$dialog
        .confirm({
          text: "网页存在未保存得数据，是否进行保存",
          title: "存在为保存行为",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确认",
              color: "blue",
              key: true
            }
          ]
        })
        .then(type => {
          if (type) {
            this.save();
          } else {
            next();
          }
        });
    } else {
      next();
    }
  },

  async created () {
    vm = this;
    // this.disable = true;
    //角色
    vm.role = vm.$root.role;
    // this.image = await Jimp.read(this.png);
    //传递的订单id
    this.passedOrderId = parseInt(this.$route.params.orderId);

    if (this.passedOrderId != -1) {
      //获得文件列表
      this.getUploadedFiles();

      //获得完成进程的输出图片
      this.getFinishedProcessResult();

      //消息订阅
      this.setWSConn();
    }
  },
  async mounted () {
    //初始化地图
    this.initMap();

    //设置leaflet控件距底部的距离
    this.setLeafletZoomBottom(52);

    //订单面板加入可拖拽事件
    dragElement(document.getElementById("order-panel"));

    //专家 可以看见任务列表
    if (this.role == "proUser" && this.passedOrderId != -1) {
      await this.getProcessList();
      if (
        this.taskList["waiting"].length > 0 ||
        this.taskList["notWaiting"].length > 0
      ) {
        this.isShowTaskList = true;
        this.setIntervalProcess();
      }
      if (this.taskList["waiting"].length > 0) {
        this.$nextTick(() => {
          // createSortable("#waiting");
        });
      }
    }

    //用户端判断订单进行到哪一步
    if (this.role == "customer") {
      if (this.passedOrderId == -1) {
        this.orderStatusIndex = 1;
        return;
      }
      let order = await this.$refs.orderInfoChild.getOrderById(
        this.passedOrderId
      );
      //找到当前订单状态的下标
      let findOrderStatusIndex = this.orderStatusArray.findIndex(
        value => value == order.status
      );
      if (findOrderStatusIndex == -1) findOrderStatusIndex = 0;
      //订单状态下标赋值
      this.orderStatusIndex = findOrderStatusIndex + 1;
    }
  },
  methods: {
    //通知提示工具
    tips (text, color) {
      this.snackbarText = text;
      this.showSnackbar = true;
      this.snackbarColor = color;
    },
    tools (type) {
      if (this.menuFiles["遥感图"][1].length != 1) {
        this.tips("至多选择一个文件", "error");
        return;
      }
      let png = this.menuFiles["遥感图"][0][this.menuFiles["遥感图"][1][0]];
      let file_type = png.name.split(".")[1];
      if (file_type != "png" && file_type != "jpg") {
        this.tips("请选择jpg或png图像", "error");
        return;
      }
      switch (type) {
        case "rotate":
          this.rotate();
          break;
        case "cut":
          this.addRectArea("cut");
          break;
        case "restore":
          this.restore();
          break;
        case "back":
          this.back();
          break;
        case "save":
          this.save();
          break;
      }
    },
    isSelectedScene (array, item) {
      console.log(item);
      let index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);
        this.removeLayerFromMap(item["imageLayer"]);
      }
    },
    setScenesSelectedArray (array) {
      console.log(array);
      this.scenesSelectedArray = array;
    },
    save () {
      let param = {
        img: this.click_img,
        orderId: this.passedOrderId,
        process: this.processStack
      };
      console.log(param);
      this.loading = true;
      axios
        .post(`${Url}imgProcess${version}`, param)
        .then(res => {
          console.log(res);
          this.loading = false;
          if (res.data == 0) {
            this.tips("保存成功", "success");
          } else {
            this.tips("保存失败", "error");
          }
          vm.processStack = [];
          this.png = "";
          vm.raster.layer.remove();
        })
        .catch(e => {
          console.log(e);
          this.loading = false;
        });
    },
    back () {
      if (this.imgStack.length < 1) {
        return;
      }
      let bounds = this.boundsStack.pop();
      // 统一边界格式
      let imageBounds = [
        [bounds._northEast.lat, bounds._southWest.lng],
        [bounds._southWest.lat, bounds._northEast.lng]
      ];
      let size = vm.sizeStack.pop();
      let img = this.imgStack.pop();
      vm.raster.layer.setUrl(img);
      vm.raster.layer.setBounds(imageBounds);
      vm.raster.layer.getElement().style.height = size[1];
      vm.raster.layer.getElement().style.width = size[0];
      this.processStack.pop();
      vm.raster.latLngs = imageBounds;
      this.png = img;
    },
    restore () {
      this.png = this.restorePng;
      var imageBounds = [
        [22.904617, 113.21201],
        [22.601074, 113.53823]
      ];
      this.processStack = [];
      this.imgStack = [];
      this.boundsStack = [];
      vm.raster.latLngs = imageBounds;
      vm.raster.layer.setBounds(imageBounds);
      vm.raster.layer.setUrl(this.restorePng);
    },
    // eslint-disable-next-line no-unused-vars
    cut (startPoint, endPoint) {
      this.imgStack.push(this.png);
      this.boundsStack.push(vm.raster.layer.getBounds());

      this.loading = true;
      Jimp.read(this.png)
        .then(image => {
          let size = vm.getSize(image, startPoint, endPoint);
          let width = size.width;
          let height = size.height;
          let sx = size.sx;
          let sy = size.sy;
          this.processStack.push({
            process: "crop",
            param: {
              x: sx,
              y: sy,
              width: width,
              height: height
            }
          });
          image.crop(sx, sy, width, height);
          vm.sizeStack.push([width, height]);
          image.getBase64(Jimp.AUTO, function (err, data) {
            vm.png = data;
            // console.log(data); // null
            vm.raster.layer.setUrl(data);
            vm.loading = false;
            vm.raster.layer.remove();
            let imageBounds = [
              [startPoint.lat, startPoint.lng],
              [endPoint.lat, endPoint.lng]
            ];

            vm.raster.latLngs = imageBounds;
            vm.raster.layer = L.imageOverlay(data, imageBounds, {
              opacity: 1,
              interactive: true
            }).addTo(vm.leafMap);
            vm.stopRectArea.destory();
          });
        })
        .catch(err => {
          // Handle an exception.
          console.log(err);
          vm.loading = false;
        });
    },
    getSize (image, startPoint, endPoint) {
      let start = vm.raster.latLngs[0];
      let end = vm.raster.latLngs[1];
      console.log(vm.raster.latLngs);
      let xScale = image.bitmap.width / (end[1] - start[1]);
      let yScale = image.bitmap.height / (start[0] - end[0]);

      // 获取左上和右下点的坐标
      // 根据经纬度计算出像素位置
      let start_point = {
        lat: startPoint.lat > endPoint.lat ? startPoint.lat : endPoint.lat,
        lng: startPoint.lng < endPoint.lng ? startPoint.lng : endPoint.lng
      };
      let end_point = {
        lat: startPoint.lat < endPoint.lat ? startPoint.lat : endPoint.lat,
        lng: startPoint.lng > endPoint.lng ? startPoint.lng : endPoint.lng
      };
      return {
        width: (end_point.lng - start_point.lng) * xScale,
        height: (start_point.lat - end_point.lat) * yScale,
        sx: (start_point.lng - start[1]) * xScale,
        sy: (start[0] - start_point.lat) * yScale
      };
    },
    rotate () {
      this.imgStack.push(this.png);
      this.boundsStack.push(vm.raster.layer.getBounds());
      this.loading = true;
      Jimp.read(this.png)
        .then(image => {
          // Do stuff with the image.
          image.rotate(90, Jimp.RESIZE_BEZIER, function (e) {
            if (e) throw e;
          });
          this.processStack.push({
            process: "rotate",
            param: {
              degrees: 90
            }
          });
          image.getBase64(Jimp.AUTO, function (err, data) {
            vm.png = data;
            vm.raster.layer.setUrl(data);
            let h = vm.raster.layer.getElement().style.height;
            let w = vm.raster.layer.getElement().style.width;
            vm.raster.layer.getElement().style.height = w;
            vm.raster.layer.getElement().style.width = h;
            vm.sizeStack.push([w, h]);
            vm.loading = false;
          });
        })
        .catch(err => {
          // Handle an exception.
          console.log(err);
          vm.loading = false;
        });
    },
    get_bounds () {
      vm.isShowParamDialog = false;
      vm.addRectArea("road");
    },
    addRectArea (type) {
      var that = this;
      var map = vm.leafMap; //获取已保存的地图
      if (that.stopRectArea != null) {
        //stopRectArea在data中定义，清除重复的拉框操作
        vm.raster.layer.off("mousedown", that.stopRectArea.mousedown);
      }
      var rectangleMeasure = {
        startPoint: null,
        startPixel: null,
        endPixel: null,
        endPoint: null,
        lastPoint: null,
        rectangle: null,
        layer: L.layerGroup(),
        color: "blue",
        addRectangle: function () {
          rectangleMeasure.destory();
          var bounds = [];
          console.log(rectangleMeasure.endPoint);
          bounds.push(rectangleMeasure.startPoint);
          bounds.push(rectangleMeasure.endPoint);
          rectangleMeasure.rectangle = L.rectangle(bounds, {
            color: rectangleMeasure.color,
            weight: 1
          });
          rectangleMeasure.rectangle.addTo(rectangleMeasure.layer);
          rectangleMeasure.layer.addTo(map);
          document.οndragstart = function () {
            return false;
          };
        },
        mousedown: function (e) {
          rectangleMeasure.rectangle = null;
          map.dragging.disable();
          rectangleMeasure.startPoint = e.latlng;
          rectangleMeasure.startPixel = e.layerPoint;
          vm.raster.layer.on("mousemove", rectangleMeasure.mousemove);
        },
        mousemove: function (e) {
          if (rectangleMeasure.lastPoint != null) {
            rectangleMeasure.endPoint = rectangleMeasure.lastPoint;
          } else {
            rectangleMeasure.endPoint = e.latlng;
          }
          rectangleMeasure.lastPoint = e.latlng;
          rectangleMeasure.addRectangle();
          // tempLines.addLatLng(e.latlng);
          vm.raster.layer.off("mousedown ", rectangleMeasure.mousedown);
          vm.raster.layer.on("mouseup", rectangleMeasure.mouseup);
        },
        // eslint-disable-next-line no-unused-vars
        mouseup: function (e) {
          map.dragging.enable();
          rectangleMeasure.endPixel = e.layerPoint;
          vm.raster.layer.off("mousemove", rectangleMeasure.mousemove);
          vm.raster.layer.off("mouseup", rectangleMeasure.mouseup);

          //在此处对数据进行处理（1）...
          // console.log(e);
          if (type == "cut") {
            vm.cut(rectangleMeasure.startPoint, rectangleMeasure.endPoint);
          } else if (type == "road") {
            vm.road_cut(rectangleMeasure.startPoint, rectangleMeasure.endPoint);
          }
        },
        destory: function () {
          if (rectangleMeasure.rectangle)
            rectangleMeasure.layer.removeLayer(rectangleMeasure.rectangle);
        }
      };
      that.stopRectArea = rectangleMeasure; //记录已点击拉框按钮，未在地图上拉框
      vm.raster.layer.on("mousedown", rectangleMeasure.mousedown); //在地图上拉框
    },
    road_cut (startPoint, endPoint) {
      console.log([startPoint, endPoint]);
      Jimp.read(this.png)
        .then(image => {
          let size = vm.getSize(image, startPoint, endPoint);
          vm.roadForms.start_point = [startPoint.lat, startPoint.lng];
          vm.roadForms.end_point = [endPoint.lat, endPoint.lng];
          vm.roadForms.image_start = vm.raster.latLngs[0];
          vm.roadForms.image_end = vm.raster.latLngs[1];
          let width = size.width;
          let height = size.height;
          let sx = size.sx;
          let sy = size.sy;
          console.log(size);
          image.crop(sx, sy, width, height);
          image.getBase64(Jimp.AUTO, function (err, data) {
            // console.log(data); // null
            vm.imgBounds = data;
          });
          vm.stopRectArea.destory();
          vm.isShowParamDialog = true;
        })
        .catch(err => {
          // Handle an exception.
          console.log(err);
          vm.loading = false;
        });
    },
    rule (type) {
      return [v => !!v || type + "不能为空"];
    },
    btpFormPanRule () {
      return [
        v => !!v || "PAN文件输出文件名不能为空",
        () =>
          this.bundleToPerfectSensorForm.panOutputPath.indexOf("PAN1") != -1 ||
          "pan文件输出名必须包含PAN1",
        () =>
          this.bundleToPerfectSensorForm.panOutputPath.endsWith(".tiff") ||
          "pan文件输出名必须以.tiff结尾",
        v => /^[A-Za-z0-9.]+$/g.test(v) || "pan文件输出名不能包含特殊字符"
      ];
    },
    btpFormMssRule () {
      return [
        v => !!v || "MSS文件输出文件名不能为空",
        () =>
          this.bundleToPerfectSensorForm.mssOutputPath.indexOf("MSS1") != -1 ||
          "mss文件输出名必须包含MSS1",
        () =>
          this.bundleToPerfectSensorForm.mssOutputPath.endsWith(".tiff") ||
          "mss文件输出名必须以.tiff结尾",
        v => /^[A-Za-z0-9.]+$/g.test(v) || "mss文件输出名不能包含特殊字符"
      ];
    },
    bundleRuleForm (type) {
      return [
        v => !!v || `${type}输出文件名不能为空`,
        v => v.endsWith(".tiff") || `${type}输出文件名必须以.tiff结尾`,
        v => /^[A-Za-z0-9.]+$/g.test(v) || `${type}输出文件名不能包含特殊字符`
      ];
    },
    bandMathFormMssRule (ortVal) {
      return [
        v => !!v || "输出文件名不能为空",
        () =>
          ortVal.outputDataPath.indexOf("MSS1") != -1 ||
          "输出文件名必须包含MSS1",
        () =>
          ortVal.outputDataPath.endsWith(".tiff") ||
          "输出文件名必须以.tiff结尾",
        v => /^[A-Za-z0-9.]+$/g.test(v) || "输出文件名不能包含特殊字符"
      ];
    },

    //登出
    logout () {
      localStorage.removeItem("username");
      localStorage.removeItem("token");
      localStorage.removeItem("refresh_token");
      this.$router.push("/");
    },
    //初始化地图
    initMap () {
      vm.leafMap = L.map("map-container", {
        attributionControl: true,
        zoomControl: false
      }).setView(mapCfg.center, mapCfg.zoom);
      mapOptions = {
        attribution:
          'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
        //attributionControl: false, //If true , an AttributionControl will be added to the map.
        maxZoom: mapCfg.maxZoom, //The maximum zoom level of the map (0-24).
        id: mapCfg.style, //	The ID of the style.
        tileSize: mapCfg.tileSize,
        zoomOffset: -1,
        accessToken: mapCfg.mapBoxAccessToken
      };
      streetMapLayer = L.tileLayer(
        "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
        mapOptions
      ).addTo(vm.leafMap);
      //缩放控件

      L.control
        .zoom({
          position: "bottomright"
        })
        .addTo(vm.leafMap);
      //比例尺
      L.control
        .scale({
          imperial: false,
          position: "bottomright"
        })
        .addTo(vm.leafMap);
    },
    //leaflet zoom 控件 设置距bottom的距离
    setLeafletZoomBottom (value) {
      document.documentElement.style.setProperty(
        "--leaflet-zoom-bottom",
        `${value}px`
      );
    },
    //卫星地图/街道图 切换显示
    switchLayer () {
      if (!this.showStreetLayer) {
        vm.leafMap.removeLayer(streetMapLayer);
        mapOptions.id = "mapbox/satellite-streets-v11";
        satelliteMapLayer = L.tileLayer(
          "https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}",
          mapOptions
        ).addTo(vm.leafMap);
      } else {
        vm.leafMap.removeLayer(satelliteMapLayer);
        streetMapLayer.addTo(vm.leafMap);
      }
    },
    //回到主页
    backMain (selectedIndex) {
      this.$router.push({
        name: "Main",
        params: { selectedType: selectedIndex }
      });
    },
    /**
     * 文件上传/下载
     */
    //上传文件
    selectedUploadFiles () {
      if (this.uploadedFile.length <= 0) {
        return;
      }
      //console.log(this.uploadedFile)
      const formData = new FormData();
      formData.append("orderId", this.passedOrderId);
      for (let file of this.uploadedFile) {
        console.log(file);
        if (file.size > 50 * 1024 * 1024) {
          this.tips("单个文件大小不能超过50MB", "error");
          return;
        }
        formData.append("files", file);
      }
      axios
        .post(`${Url}uploadFile${version}`, formData, {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        })
        .then(res => {
          if (res.data == "ok") {
            this.tips("上传成功", "success");
            this.getUploadedFiles();
            return;
          }
          this.tips("上传失败", "error");
        })
        .catch(error => {
          console.log(error);
          this.tips("上传失败", "error");
        });
    },
    //获得上传过的文件列表
    async getUploadedFiles () {
      let res = await axios.get(`${Url}getUploadedFiles${version}`, {
        params: { orderId: this.passedOrderId }
      });
      console.log(res.data);
      for (let file of res.data) {
        file.time = commonCfg.dateToString(new Date(file.uploadedTime));
        if (file.size > 1024 * 1024 * 1024) {
          file.size =
            (Math.round((file.size / 1024 / 1024 / 1024) * 100) / 100).toFixed(
              2
            ) + "GB";
        } else if (file.size > 1024 * 1024) {
          file.size =
            (Math.round((file.size / 1024 / 1024) * 100) / 100).toFixed(2) +
            "MB";
        } else if (file.size > 1024) {
          file.size =
            (Math.round((file.size / 1024) * 100) / 100).toFixed(2) + "KB";
        } else {
          file.size = file.size + "B";
        }
        file.showDownloadLoading = false;
      }
      this.fileList = res.data;
    },
    deleteReportFile (id) {
      this.$dialog
        .confirm({
          text: "确认删除此文件吗？删除后不可撤销",
          title: "确认删除？",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确定",
              color: "blue",
              key: true
            }
          ]
        })
        .then(async type => {
          if (!type) return;
          let res = await axios.get(`${Url}deleteFileById${version}`, {
            params: { fileId: id }
          });
          if (res.data == "ok") {
            this.tips("删除成功", "success");
            this.getUploadedFiles();
          } else {
            this.tips("删除失败", "error");
          }
        });
    },
    /**
     * websocket 消息发送/接收
     */
    setWSConn () {
      wsConn = new WebSocket(
        `wss://remotesensing.live/message/${this.passedOrderId}`
      ); //wss remotesensing.live
      wsConn.onopen = this.handleWSOpen;
      wsConn.onclose = this.handleWSClose;
      wsConn.onerror = this.handleWSError;
      wsConn.onmessage = this.handleWSMessage;
    },
    //服务端连接关闭
    handleWSClose (evt) {
      console.log(evt);
      //状态码1000 表示正常关闭
      if (evt.code === 1000) return;
      if (evt.code === 4001 || evt.code === 4002) {
        //4001 token过期
        //4002 未从cookie取到token
        this.tips("请重新登录", "error");
        // this.logout();
      } else {
        //
        setTimeout(vm.setWSConn, 3000);
        //1006 错误码一般出现在断网时
      }
    },
    //连接成功建立的回调方法
    handleWSOpen () {
      console.log("connection success");
    },
    //连接发生错误的回调方法
    handleWSError (evt) {
      console.log(evt);
      wsConn.onclose = undefined; //加上这个能正常关闭
      wsConn.close(1000);
    },
    //接收到消息的回调方法
    handleWSMessage (evt) {
      let findIndex = this.messages.findIndex(msg => {
        let data = JSON.parse(evt.data);
        return data.sender == msg.sender && data.sendTime == msg.sendTime;
      });
      console.log(findIndex);
      if (findIndex != -1) return; //原来数组里存在该消息，则不进行存储，避免websocket重连时发送重复消息

      //strinconsole.log(evt.data)
      let msg = JSON.parse(evt.data);
      //console.log(msg)
      msg["sendTimeStr"] = commonCfg.dateToString(new Date(msg.sendTime));
      msg["avatarName"] = msg.sender.substring(0, 2);
      this.messages.push(msg);
    },
    //提交消息
    submitMessage () {
      if (!this.messageContent) {
        this.tips("请输入消息", "error");
        return;
      }
      let param = {
        orderId: this.passedOrderId,
        messageContent: this.messageContent,
        sender: localStorage.getItem("username"),
        sendTime: new Date().getTime()
      };
      wsConn.send(JSON.stringify(param));
      this.messageContent = "";
    },

    //任务状态格式化
    taskStatusFormat (status) {
      let statusFormat = "";
      switch (status) {
        case "processing":
          statusFormat = "正在进行......";
          break;
        case "wait":
          statusFormat = "等待中......";
          break;
        case "pause":
          statusFormat = "暂停中......";
          break;
        case "end":
          statusFormat = "已完成......";
          break;
        default:
          statusFormat = "";
      }
      return statusFormat;
    },

    //切片显示
    async showTileLayer (flag, item, childProcessIndex) {
      let path = "";
      if (flag != "childTile") {
        if (flag == "origin") {
          path = `scenes/showData/${item.png_path}`;
        } else if (flag == "finishedImg") {
          let end = item.name.substring(item.name.lastIndexOf(".") + 1);
          if (end == "png") {
            let imageBounds = [
              [22.904617, 113.21201],
              [22.601074, 113.53823]
            ];
            let path =
              //  process.env.BASE_URL +
              "https://remotesensing.live/" +
              item.tilePath.substring(0, item.tilePath.lastIndexOf("-")) +
              "/" +
              item.name;
            this.png = path;
            this.click_img = item.tilePath;
            this.restorePng = this.png;
            console.log(path);
            item["opacity"] = 1;
            vm.raster.layer = L.imageOverlay(path, imageBounds, {
              opacity: item["opacity"],
              interactive: true
            }).addTo(vm.leafMap);

            vm.raster.latLngs = imageBounds;
            item["imageLayer"] = vm.raster.layer;
            // add the image overlay,
            // so that it covers the entire map
            // L.imageOverlay(url, bounds).addTo(leafMap);
            // tell leaflet that the map is exactly as big as the image
            vm.leafMap.fitBounds(imageBounds);
            return;
          }
          path = item.tilePath.substring(0, item.tilePath.lastIndexOf("."));
        }
        // X: 经度 Y: 纬度
        //leaflet  if the area crosses the antimeridian, you must specify corners outside the [-180, 180] degrees longitude range.
        let extent = JSON.parse(item.extent);
        let xLT = extent["X-LT"];
        let xRB = extent["X-RB"];
        let LTCoordArray = [extent["Y-LT"], xLT];
        let RBCoordArray = [extent["Y-RB"], xRB];
        let imageBounds = [LTCoordArray, RBCoordArray];
        vm.leafMap.fitBounds(imageBounds);
      } else {
        //子任务 切片显示
        let res = await this.getProcessById(item.id);
        path = res.childProcesses[childProcessIndex].tilesPath;
        if (path) {
          path = path.substring(2, path.length);
          //path = path.replace("showData", "scenes")
        }
      }
      item["opacity"] = 1;
      let layer = L.tileLayer(
        process.env.BASE_URL + `${path}/{z}/{x}/{y}.png`, //https://remotesensing.live/rstp/statics/scenes/rst-admin/222/GF2_PMS1_E113.4_N22.8_20150123_L1A0000607625-MSS1_3 file:///E:/TileDirectory
        {
          tms: true,
          opacity: item["opacity"],
          attribution: "",
          minZoom: 1,
          maxZoom: 18
        }
      ).addTo(vm.leafMap);
      console.log(process.env.BASE_URL, layer);
      if (flag != "childTile") item["imageLayer"] = layer;
    },
    //从地图上删除scene layer
    removeLayerFromMap (layer) {
      if (layer) {
        vm.leafMap.removeLayer(layer);
      }
    },

    //订单面板显示/隐藏时 显示/隐藏选中的scene图片
    isShowOrderData () {
      console.log(this.showOrderPanel);
      if (this.showOrderPanel) {
        for (let scene of this.scenesSelectedArray) {
          console.log(scene);
          this.showTileLayer("origin", scene);
        }
      } else {
        for (let scene of this.scenesSelectedArray) {
          this.removeLayerFromMap(scene["imageLayer"]);
        }
      }
    },
    //工具参数标题名
    showParamDialog (name, info) {
      if (
        name == this.advanceToolList[3].name ||
        name == this.advanceToolList[2].name
      ) {
        this.selectedFileIndex = 1;
        this.selectedFileType[0].disable = true;
      } else {
        this.selectedFileIndex = 0;
        this.selectedFileType[0].disable = false;
      }
      this.paramDiglogTitle = name;
      this.toolsInfo = info;
    },
    //参数 文件列表 取消选中的scene记录
    cancelSelectedOriginScene (item) {
      this.isSelectedScene(this.scenesSelectedArray, item);
    },
    //遥感图列表 取消/保存选中的处理后的图片
    changeImages (selectedImages) {
      for (let i = 0; i < this.imageList.length; i++) {
        if (this.imageList[i]["imageLayer"]) {
          if (selectedImages.indexOf(i) <= -1) {
            vm.leafMap.removeLayer(this.imageList[i]["imageLayer"]);
            this.imageList[i]["imageLayer"] = undefined;
          }
        } else {
          selectedImages.indexOf(i) > -1
            ? this.showTileLayer("finishedImg", this.imageList[i])
            : "";
        }
      }
    },
    //参数 遥感图列表 取消选中的处理后的图片
    cancelSelectedImage (selectedImages, item, index) {
      console.log(selectedImages);
      if (this.imageList[item]["imageLayer"]) {
        vm.leafMap.removeLayer(this.imageList[item]["imageLayer"]);
        this.imageList[item]["imageLayer"] = undefined;
        selectedImages.splice(index, 1);
      }
    },
    changeSatType (satTpye) {
      console.log(satTpye);
      this.satType = satTpye;
    },
    //设置layer的透明度
    changeLayerOpacity (layer, opacityVal) {
      console.log(layer, opacityVal);
      layer.setOpacity(opacityVal);
    },
    //定时 不断地取每个任务的状态
    setIntervalProcess () {
      processInterval = setInterval(async () => {
        let flag = true;
        console.log(vm.taskList);
        //不是等待中的任务
        for (let task of vm.taskList["notWaiting"]) {
          if (task.status == "processing") {
            //正在进行
            let res = await vm.getProcessById(task.id);
            if (task.status == res.status) {
              flag = false;
              //continue;
            } //任务 状态赋值
            task.status = res.status;
            for (let i = 0; i < res.childProcesses.length; i++) {
              //子任务状态赋值
              task.childProcesses[i].status = res.childProcesses[i].status;
              task.childProcesses[i].tilesStatus =
                res.childProcesses[i].tilesStatus;
            }
          } else if (task.status == "wait") {
            //暂停后重新开始 可能会出现的情况
            vm.taskList["notWaiting"].splice(
              vm.taskList["notWaiting"].indexOf(task),
              1
            ); //从非等待队列中删除
            vm.taskList["waiting"].push(task); //添加到等待队列
            flag = false;
          }
        }
        //等待中的任务
        for (let task of vm.taskList["waiting"]) {
          if (task.status == "wait") {
            let res = await vm.getProcessById(task.id);
            if (task.status == res.status) {
              flag = false;
              //continue;
            } else if (res.status == "processing") {
              //运行状态 重新赋值
              task.status = res.status;
              for (let i = 0; i < res.childProcesses.length; i++) {
                task.childProcesses[i].status = res.childProcesses[i].status;
                task.childProcesses[i].tilesStatus =
                  res.childProcesses[i].tilesStatus;
              }
              vm.taskList["waiting"].splice(
                vm.taskList["waiting"].indexOf(task),
                1
              ); //从等待队列中删除
              vm.taskList["notWaiting"].push(task); //添加到非等待队列
              flag = false;
              //continue
            }
            //task.status = res.status;
          } else if (task.status == "processing") {
            //暂停后重新开始 可能会出现的情况
            vm.taskList["waiting"].splice(
              vm.taskList["waiting"].indexOf(task),
              1
            ); //从等待队列中删除
            vm.taskList["notWaiting"].push(task); //添加到非等待队列
            flag = false;
          }
        }
        //清除定时器
        if (flag) {
          clearInterval(processInterval);
          processInterval = null;
        }
      }, 12000);
    },
    //参数提交
    async paramSubmit () {
      switch (this.paramDiglogTitle) {
        case this.advanceToolList[0].name:
          this.bundleParamSubmit(); //影像融合
          break;
        case this.advanceToolList[1].name:
          this.bandMathParamSubmit(); //波段运算
          break;
        case this.advanceToolList[2].name:
          this.vegetationCoverageSubmit(); //植被覆盖度
          break;
        case this.advanceToolList[3].name:
          this.mergeSubmit(); //镶嵌
          break;
        case this.advanceToolList[4].name:
          this.buildingSubmit(); //建筑指数
          break;
        case this.advanceToolList[5].name:
          this.histogramSubmit(); //直方图均衡化
          break;
        case this.advanceToolList[6].name:
          this.linearSubmit(); //百分比拉伸
          break;
        case this.advanceToolList[7].name:
          this.corrosionSubmit(); //膨胀与腐蚀
          break;
        case this.advanceToolList[8].name:
          this.changeSubmit(); //变化检测
          break;
        case this.advanceToolList[9].name:
          this.radartSubmit(); //雷达干涉
          break;
        case this.advanceToolList[10].name:
          this.roadSubmit(); //道路提取
          break;
        default:
      }
      //console.log(this.paramDiglogTitle)
      //正射校正
    },
    async vegetationCoverageSubmit () {
      // if (this.selectedFileIndex == 0) {
      //   if (this.scenesSelectedArray.length <= 0) {
      //     this.tips("请先选择要操作的文件", "error");
      //     return;
      //   }
      // }
      if (!this.$refs.coverForms.validate()) {
        return;
      }
      if (this.menuFiles["遥感图"][1].length != 1) {
        this.tips("至多选择一个文件", "error");
        return;
      }
      let param;
      let inputPath = this.imageList[
        this.menuFiles["遥感图"][1]
      ].tilePath.split("/");
      param = {
        //参数
        orderId: this.passedOrderId,
        process: "Gdal.calc.vc", //process里面的bm和下面参数里面的bm相对应
        vc: {
          //bm参数是自定义的，任意起
          inputData: inputPath[2] + "/" + inputPath[3],
          outputData: this.coverForms.outputPath,
          demDir: "srtm_wh.tif",
          band: "1",
          percentile: "2,98",
          version: "latest"
        }
      };
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[2].name;
      taskObj["imageName"] = param.vc.outputData;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    async mergeSubmit () {
      if (!this.$refs.mergeForms.validate()) {
        return;
      }
      // if (this.selectedFileIndex == 0) {
      //   if (this.scenesSelectedArray.length <= 0) {
      //     this.tips("请先选择要操作的文件", "error");
      //     return;
      //   }
      // }
      if (this.menuFiles["遥感图"][1].length < 2) {
        this.tips("至少选择两个文件", "error");
        return;
      }
      let param;
      // let inputPath = this.imageList[1].tilePath.split("/");
      param = {
        //参数
        orderId: this.passedOrderId,
        process: "Gdal.merge.mg", //process里面的bm和下面参数里面的bm相对应
        mg: {
          //bm参数是自定义的，任意起
          inputData: [],
          outputData: this.mergeForms.outputPath,
          init: this.mergeForms.band,
          band: "1",
          percentile: "2,98",
          version: "latest"
        }
      };
      for (let index of this.menuFiles["遥感图"][1]) {
        console.log(this.imageList[index]);
        console.log(index);
        param.mg.inputData.push(
          this.imageList[index].tilePath.split("/")[2] +
          "/" +
          this.imageList[index].tilePath.split("/")[3]
        );
      }
      console.log(param);
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[3].name;
      taskObj["imageName"] = param.outputData;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    async buildingSubmit () {
      let param;
      console.log(this.scenesSelectedArray);
      if (this.selectedFileIndex == 0) {
        //从检索出来的原文件进行选择
        if (this.scenesSelectedArray.length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.scenesSelectedArray.length > 1) {
          this.tips("至多选择一个文件", "error");
          return;
        }
        if (!this.$refs.buildingForms.validate()) {
          return;
        }

        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.mbi.mbi", //process里面的bm和下面参数里面的bm相对应
          mbi: {
            //bm参数是自定义的，任意起
            inputData: `${this.scenesSelectedArray[0].png_path.split("-")[0]
              }/${this.scenesSelectedArray[0].png_path + ".tiff"}`,
            outputData1: this.buildingForms.outputPath1,
            outputData2: this.buildingForms.outputPath2,
            s_min: this.buildingForms.s_min,
            s_max: this.buildingForms.s_max,
            delta_s: this.buildingForms.delta_s,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      } else {
        //从之前完成的图片里进行选择
        if (this.menuFiles["遥感图"][1].length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.menuFiles["遥感图"][1].length > 1) {
          this.tips("至多选择一个文件", "error");
          return;
        }
        // let selectedArr = [this.imageList[this.menuFiles["遥感图"][1][0]]];
        let inputPath = this.imageList[
          this.menuFiles["遥感图"][1]
        ].tilePath.split("/");
        if (!this.$refs.buildingForms.validate()) {
          return;
        }
        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.mbi.mbi", //process里面的bm和下面参数里面的bm相对应
          mbi: {
            //bm参数是自定义的，任意起
            inputData: inputPath[2] + "/" + inputPath[3],
            outputData1: this.buildingForms.outputPath1,
            outputData2: this.buildingForms.outputPath2,
            s_min: this.buildingForms.s_min,
            s_max: this.buildingForms.s_max,
            delta_s: this.buildingForms.delta_s,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      }
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[4].name;
      taskObj["imageName"] = param.mbi.outputData1;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    async histogramSubmit () {
      let param;
      if (this.selectedFileIndex == 0) {
        //从检索出来的原文件进行选择
        if (this.scenesSelectedArray.length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.scenesSelectedArray.length > 1) {
          this.tips("至多选择一个文件", "error");
          return;
        }
        if (!this.$refs.histogramForms.validate()) {
          return;
        }
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.histogram.his", //process里面的bm和下面参数里面的bm相对应
          his: {
            //bm参数是自定义的，任意起
            inputData: `${this.scenesSelectedArray[0].png_path.split("-")[0]
              }/${this.scenesSelectedArray[0].png_path + ".tiff"}`,
            outputData: this.histogramForms.outputPath,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      } else {
        //从之前完成的图片里进行选择
        if (this.menuFiles["遥感图"][1].length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.menuFiles["遥感图"][1].length > 1) {
          this.tips("至多选择一个文件", "error");
          return;
        }
        // let selectedArr = [this.imageList[this.menuFiles["遥感图"][1][0]]];
        let inputPath = this.imageList[
          this.menuFiles["遥感图"][1]
        ].tilePath.split("/");
        if (!this.$refs.histogramForms.validate()) {
          return;
        }
        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.histogram.his", //process里面的bm和下面参数里面的bm相对应
          his: {
            //bm参数是自定义的，任意起
            inputData: inputPath[2] + "/" + inputPath[3],
            outputData: this.histogramForms.outputPath,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      }
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[5].name;
      taskObj["imageName"] = param.his.outputData;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    async linearSubmit () {
      let param;
      if (this.selectedFileIndex == 0) {
        //从检索出来的原文件进行选择
        if (this.scenesSelectedArray.length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.scenesSelectedArray.length > 1) {
          this.tips("至多选择一个文件", "error");
          return;
        }
        if (!this.$refs.linearForms.validate()) {
          return;
        }
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.linear.line", //process里面的bm和下面参数里面的bm相对应
          line: {
            //bm参数是自定义的，任意起
            inputData: `${this.scenesSelectedArray[0].png_path.split("-")[0]
              }/${this.scenesSelectedArray[0].png_path + ".tiff"}`,
            outputData: this.linearForms.outputPath,
            percentage: this.linearForms.percentage,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      } else {
        //从之前完成的图片里进行选择
        if (this.menuFiles["遥感图"][1].length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.menuFiles["遥感图"][1].length > 1) {
          this.tips("至多选择一个文件", "error");
          return;
        }
        // let selectedArr = [this.imageList[this.menuFiles["遥感图"][1][0]]];
        let inputPath = this.imageList[
          this.menuFiles["遥感图"][1]
        ].tilePath.split("/");
        if (!this.$refs.linearForms.validate()) {
          return;
        }
        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.linear.line", //process里面的bm和下面参数里面的bm相对应
          line: {
            //bm参数是自定义的，任意起
            inputData: inputPath[2] + "/" + inputPath[3],
            outputData: this.linearForms.outputPath,
            percentage: this.linearForms.percentage,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      }
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[6].name;
      taskObj["imageName"] = param.line.outputData;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    async corrosionSubmit () {
      let param;
      if (this.selectedFileIndex == 0) {
        //从检索出来的原文件进行选择
        if (this.scenesSelectedArray.length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.scenesSelectedArray.length > 1) {
          this.tips("至多选择一个文件", "error");
          return;
        }
        if (!this.$refs.corrosionForms.validate()) {
          return;
        }

        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.corrosion.cor", //process里面的bm和下面参数里面的bm相对应
          cor: {
            //bm参数是自定义的，任意起
            inputData: `${this.scenesSelectedArray[0].png_path.split("-")[0]
              }/${this.scenesSelectedArray[0].png_path + ".tiff"}`,
            outputData1: this.corrosionForms.outputPath1,
            outputData2: this.corrosionForms.outputPath2,
            outputData3: this.corrosionForms.outputPath3,
            outputData4: this.corrosionForms.outputPath4,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      } else {
        //从之前完成的图片里进行选择
        if (this.menuFiles["遥感图"][1].length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.menuFiles["遥感图"][1].length > 1) {
          this.tips("至多选择一个文件", "error");
          return;
        }
        // let selectedArr = [this.imageList[this.menuFiles["遥感图"][1][0]]];
        let inputPath = this.imageList[
          this.menuFiles["遥感图"][1]
        ].tilePath.split("/");
        if (!this.$refs.corrosionForms.validate()) {
          return;
        }
        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.corrosion.cor", //process里面的bm和下面参数里面的bm相对应
          cor: {
            //bm参数是自定义的，任意起
            inputData: inputPath[2] + "/" + inputPath[3],
            outputData1: this.corrosionForms.outputPath1,
            outputData2: this.corrosionForms.outputPath2,
            outputData3: this.corrosionForms.outputPath3,
            outputData4: this.corrosionForms.outputPath4,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      }
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[7].name;
      taskObj["imageName"] = param.cor.outputData1;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    async changeSubmit () {
      let param;
      console.log(this.scenesSelectedArray);
      if (this.selectedFileIndex == 0) {
        //从检索出来的原文件进行选择
        if (this.scenesSelectedArray.length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.scenesSelectedArray.length != 2) {
          this.tips("请选择两张图片", "error");
          return;
        }
        if (!this.$refs.changeForms.validate()) {
          return;
        }

        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.change.change", //process里面的bm和下面参数里面的bm相对应
          change: {
            //bm参数是自定义的，任意起
            inputData1: `${this.scenesSelectedArray[0].png_path.split("-")[0]
              }/${this.scenesSelectedArray[0].png_path + ".tiff"}`,
            inputData2: `${this.scenesSelectedArray[1].png_path.split("-")[0]
              }/${this.scenesSelectedArray[1].png_path + ".tiff"}`,
            outputData: this.changeForms.outputPath,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      } else {
        //从之前完成的图片里进行选择
        if (this.menuFiles["遥感图"][1].length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.menuFiles["遥感图"][1].length != 2) {
          this.tips("请选择两张图片", "error");
          return;
        }
        console.log(this.menuFiles["遥感图"][1]);
        // let selectedArr = [this.imageList[this.menuFiles["遥感图"][1][0]]];
        let inputPath1 = this.imageList[
          this.menuFiles["遥感图"][1][0]
        ].tilePath.split("/");
        let inputPath2 = this.imageList[
          this.menuFiles["遥感图"][1][1]
        ].tilePath.split("/");
        if (!this.$refs.changeForms.validate()) {
          return;
        }
        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.change.change", //process里面的bm和下面参数里面的bm相对应
          change: {
            //bm参数是自定义的，任意起
            inputData1: inputPath1[2] + "/" + inputPath1[3],
            inputData2: inputPath2[2] + "/" + inputPath2[3],
            outputData: this.changeForms.outputPath,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      }
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[8].name;
      taskObj["imageName"] = param.change.outputData;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    async radartSubmit () {
      let param;
      console.log(this.scenesSelectedArray);
      if (this.selectedFileIndex == 0) {
        //从检索出来的原文件进行选择
        if (this.scenesSelectedArray.length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.scenesSelectedArray.length != 2) {
          this.tips("请选择两张图片", "error");
          return;
        }
        if (!this.$refs.radarForms.validate()) {
          return;
        }

        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Snap.radarInterference.radar", //process里面的bm和下面参数里面的bm相对应
          radar: {
            //bm参数是自定义的，任意起
            inputData1: `${this.scenesSelectedArray[0].png_path.split("-")[0]
              }/${this.scenesSelectedArray[0].png_path + ".zip"}`,
            inputData2: `${this.scenesSelectedArray[1].png_path.split("-")[0]
              }/${this.scenesSelectedArray[1].png_path + ".zip"}`,
            outputData: this.radarForms.outputPath,
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      }
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[9].name;
      taskObj["imageName"] = param.radar.outputData;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    async roadSubmit () {
      let param;
      if (this.selectedFileIndex == 1) {
        //从检索出来的原文件进行选择
        if (this.menuFiles["遥感图"][1].length != 1) {
          this.tips("请选择一个文件", "error");
          return;
        }
        if (!this.$refs.roadForms.validate()) {
          return;
        }
        let inputPath = this.imageList[
          this.menuFiles["遥感图"][1]
        ].tilePath.split("/");
        let imageName = inputPath[3].split("-")[0] + ".tiff";
        // let inputPath = this.imageList[1].tilePath.split("/");
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Gdal.road.road", //process里面的bm和下面参数里面的bm相对应
          road: {
            //bm参数是自定义的，任意起

            inputData: inputPath[2] + "/" + imageName,
            outputData: this.roadForms.outputPath,
            startPoint:
              this.roadForms.start_point[0] +
              "," +
              this.roadForms.start_point[1],
            endPoint:
              this.roadForms.end_point[0] + "," + this.roadForms.end_point[1],
            imageStart:
              this.roadForms.image_start[0] +
              "," +
              this.roadForms.image_start[1],
            imageEnd:
              this.roadForms.image_end[0] + "," + this.roadForms.image_end[1],
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
      }
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[10].name;
      taskObj["imageName"] = param.road.outputData;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    //影像融合 BundleToPerfectSensor影像融合需要先正射校正 必须是一个pan文件和一个mss文件
    async bundleParamSubmit () {
      let param;

      if (this.selectedFileIndex == 0) {
        //从检索出来的原文件进行选择
        if (this.scenesSelectedArray.length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.scenesSelectedArray.length != 2) {
          this.tips("至多选择一个PAN文件和一个MSS文件", "error");
          return;
        }
        let pan1Index = this.scenesSelectedArray.findIndex(
          obj => obj.png_path.indexOf("PAN1") != -1
        ); //pan文件下标
        let mss1Index = this.scenesSelectedArray.findIndex(
          obj => obj.png_path.indexOf("MSS1") != -1
        ); //mss文件下标
        if (pan1Index == -1 || mss1Index == -1) {
          this.tips("至多选择一个PAN文件和一个MSS文件", "error");
          return;
        }
        if (!this.$refs.bundleToPerfectSensorForm.validate()) {
          return;
        }
        param = {
          //参数 orthoRectification：正射校正 bundleToPerfectSensor：影像融合 mss pan btp要和下面参数对应
          orderId: this.passedOrderId,
          process:
            "Otb.orthoRectification.mss|Otb.orthoRectification.pan&Otb.bundleToPerfectSensor.btp",
          pan: {
            x: this.bundleToPerfectSensorForm.panX,
            y: this.bundleToPerfectSensorForm.panY,
            outputDataPath: this.bundleToPerfectSensorForm.panOutputPath,
            inputDataPath: `${this.scenesSelectedArray[pan1Index].png_path.split("-")[0]
              }/${this.scenesSelectedArray[pan1Index].png_path + ".tiff"}`,
            demDir: "srtm_wh.tif",
            band: "1",
            percentile: "2,98",
            version: "latest"
          },
          mss: {
            x: this.bundleToPerfectSensorForm.mssX,
            y: this.bundleToPerfectSensorForm.mssY,
            outputDataPath: this.bundleToPerfectSensorForm.mssOutputPath,
            inputDataPath: `${this.scenesSelectedArray[mss1Index].png_path.split("-")[0]
              }/${this.scenesSelectedArray[mss1Index].png_path + ".tiff"}`,
            demDir: "srtm_wh.tif",
            band: "3,2,1",
            percentile: "2,98",
            version: "latest"
          },
          btp: {
            panInputDataPath: "pan.outputDataPath",
            mssInputDataPath: "mss.outputDataPath",
            outputPath: this.bundleToPerfectSensorForm.bundleOutputPath,
            band: "3,2,1",
            percentile: "2,98",
            version: "latest"
          }
        };
      } else {
        //从之前完成的图片里进行选择
        if (this.menuFiles["遥感图"][1].length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        if (this.menuFiles["遥感图"][1].length != 2) {
          this.tips("至多选择一个PAN文件和一个MSS文件", "error");
          return;
        }
        let selectedArr = [
          this.imageList[this.menuFiles["遥感图"][1][0]],
          this.imageList[this.menuFiles["遥感图"][1][1]]
        ];
        let pan1Index = selectedArr.findIndex(
          obj => obj.png_path.indexOf("PAN1") != -1
        ); //pan文件下标
        let mss1Index = selectedArr.findIndex(
          obj => obj.png_path.indexOf("MSS1") != -1
        ); //mss文件下标
        if (pan1Index == -1 || mss1Index == -1) {
          this.tips("至多选择一个PAN文件和一个MSS文件", "error");
          return;
        }
        if (!this.$refs.bundleToPerfectSensorForm.validate()) {
          return;
        }
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Otb.bundleToPerfectSensor.btp",
          btp: {
            panInputDataPath:
              selectedArr[pan1Index].tilePath.split("/")[2] +
              "/" +
              selectedArr[pan1Index].tilePath.split("/")[3],
            mssInputDataPath:
              selectedArr[mss1Index].tilePath.split("/")[2] +
              "/" +
              selectedArr[mss1Index].tilePath.split("/")[3],
            outputPath: this.bundleToPerfectSensorForm.bundleOutputPath,
            band: "3,2,1",
            percentile: "2,98",
            version: "latest"
          }
        };
        console.log(param, selectedArr);
      }
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      //let resParam = JSON.parse(resProcess.param);
      //let paths = resParam.btp.outputDataPath.split("/");
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[0].name;
      //taskObj["imageName"] = paths[paths.length - 1];
      taskObj["imageName"] = param.btp.outputPath;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      //this.taskList.push(taskObj)
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    //波段运算 用mss类型的文件进行测试 也需要先正射校正
    async bandMathParamSubmit () {
      let param;
      if (this.satType == "GF2") {
        param = this.GF2();
      } else {
        param = this.LANDSAT();
      }

      console.log(param);
      let orthoRes = await this.orthoRectificationHandle(param);
      let resProcess = await vm.getProcessById(orthoRes.msg);
      let taskObj = resProcess;
      taskObj["operation"] = this.advanceToolList[1].name;
      taskObj["imageName"] = param.bm.outputData;
      taskObj["isPauseBtn"] = true;
      taskObj["showSubProcess"] = false;
      if (taskObj.status == "wait") {
        this.taskList["waiting"].push(taskObj);
      } else {
        this.taskList["notWaiting"].push(taskObj);
      }
      if (!processInterval) {
        this.setIntervalProcess(); //启动定时
      }
      this.isShowParamDialog = false;
      this.isShowTaskList = true;
    },
    LANDSAT () {
      console.log(this.scenesSelectedArray);
      let LANDSATIndex = this.scenesSelectedArray.findIndex(
        obj => obj.sat_id.indexOf("LANDSAT") == -1
      );
      if (LANDSATIndex != -1) {
        this.tips("只能选择LANDSAT类型的文件", "error");
        return;
      }
      let param;
      param = {
        //参数
        orderId: this.passedOrderId,
        process: "", //process里面的bm和下面参数里面的bm相对应
        bm: {
          //bm参数是自定义的，任意起
          inputData: [],
          outputData: this.bandMathForms.bm.outputPath,
          expression: `'${this.bandMathForms.bm.expression}'`,
          demDir: "srtm_wh.tif",
          band: "1",
          percentile: "2,98",
          version: "latest"
        }
      };
      if (this.selectedFileIndex == 0) {
        for (let scenesSelected of this.scenesSelectedArray) {
          console.log();
          let nameIndex = scenesSelected.png_path.lastIndexOf("_");
          console.log(scenesSelected.png_path.substring(0, nameIndex));
          param.bm.inputData.push(
            scenesSelected.png_path.substring(0, nameIndex) +
            "/" +
            scenesSelected.png_path
          );
        }
      } else {
        for (let index of this.menuFiles["遥感图"][1]) {
          param.bm.inputData.push(
            this.imageList[index].tilePath.split("/")[2] +
            "/" +
            this.imageList[index].tilePath.split("/")[3]
          );
        }
      }

      param.process = `Otb.bandMath.bm`;
      return param;
    },
    GF2 () {
      let param;
      if (this.selectedFileIndex == 0) {
        //从检索出来的原文件进行选择
        if (this.scenesSelectedArray.length <= 0) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        console.log(this.bandMathForms);

        let index = this.scenesSelectedArray.findIndex(
          scene => scene.png_path.indexOf("MSS1") == -1
        );
        if (index != -1) {
          this.tips("只能选择MSS类型的文件", "error");
          return;
        }
        if (!this.$refs.bandMathForms.validate()) {
          return;
        }

        param = {
          //参数
          orderId: this.passedOrderId,
          process: "", //process里面的bm和下面参数里面的bm相对应
          bm: {
            //bm参数是自定义的，任意起
            inputData: [],
            outputData: this.bandMathForms.bm.outputPath,
            expression: `'${this.bandMathForms.bm.expression}'`,
            demDir: "srtm_wh.tif",
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
        let processStr = "";
        console.log(this.bandMathForms["ort"]);
        if (this.bandMathForms["ort"] == null) {
          console.log(this.menuFiles["遥感图"]);
        }
        for (let key in this.bandMathForms["ort"]) {
          if (processStr) {
            processStr = `${processStr}|Otb.orthoRectification.${key}`;
          } else {
            processStr = `Otb.orthoRectification.${key}`;
          }
          this.bandMathForms["ort"][key]["x"] = Number(
            this.bandMathForms["ort"][key]["x"]
          );
          this.bandMathForms["ort"][key]["y"] = Number(
            this.bandMathForms["ort"][key]["y"]
          );
          param[key] = this.bandMathForms["ort"][key];
          param.bm.inputData.push(`${key}.outputDataPath`);
        }
        param.process = `${processStr}&Otb.bandMath.bm`;
      } else {
        //从之前完成的图片里进行选择
        if (
          !this.menuFiles["遥感图"] ||
          this.menuFiles["遥感图"][1].length <= 0
        ) {
          this.tips("请先选择要操作的文件", "error");
          return;
        }
        let index = this.menuFiles["遥感图"][1].findIndex(
          obj => this.menuFiles["遥感图"][0][obj].png_path.indexOf("MSS1") == -1
        );
        if (index != -1) {
          this.tips("只能选择MSS类型的文件", "error");
          return;
        }
        if (!this.$refs.bandMathForms.validate()) {
          return;
        }
        param = {
          //参数
          orderId: this.passedOrderId,
          process: "Otb.bandMath.bm", //process里面的bm和下面参数里面的bm相对应
          bm: {
            inputData: [],
            outputData: this.bandMathForms.bm.outputPath,
            expression: `'${this.bandMathForms.bm.expression}'`,
            demDir: "srtm_wh.tif",
            band: "1",
            percentile: "2,98",
            version: "latest"
          }
        };
        console.log(this.menuFiles["遥感图"]);
        for (let index of this.menuFiles["遥感图"][1]) {
          console.log(this.imageList[index]);
          console.log(index);
          param.bm.inputData.push(
            this.imageList[index].tilePath.split("/")[2] +
            "/" +
            this.imageList[index].tilePath.split("/")[3]
          );
        }
      }
      return param;
    },
    //获得用户正在处理和等待中的的任务列表
    async getProcessList () {
      this.taskList["waiting"] = [];
      this.taskList["notWaiting"] = [];
      let res = await axios.post(
        `${Url}getProcess${version}`,
        qs.stringify({ orderId: this.passedOrderId })
      );
      for (let ps of res.data) {
        let resProcess = await vm.getProcessById(ps.id);
        let taskObj = resProcess;

        taskObj.btnDisable = false;
        taskObj.btnLoading = false;
        let resParam = JSON.parse(resProcess.param);
        let path = [];
        // 获取最后一个进程的key
        let param_keys = resParam.process.split(".");
        let param_key = param_keys[param_keys.length - 1];
        console.log(param_key);
        // let output = "";
        let key = Object.keys(resParam[param_key]).filter(
          v => !v.indexOf("output")
        )[0];
        console.log(key);
        path = resParam[param_key][key].split("/");

        // if (ps.process.indexOf("Otb.bundleToPerfectSensor.btp") != -1) {
        //   path = resParam.btp.outputPath.split("/");
        // } else if (ps.process.indexOf("Otb.bandMath.bm") != -1) {
        //   path = resParam.bm.outputData.split("/");
        // }
        console.log(this.advanceToolList);
        // taskObj["operation"] = this.advanceToolList[
        //   this.advanceToolList.length - 1
        // ].name;

        taskObj["imageName"] = path[path.length - 1];
        taskObj.status == "pause"
          ? (taskObj["isPauseBtn"] = false)
          : (taskObj["isPauseBtn"] = true);
        taskObj["showSubProcess"] = false;
        if (taskObj.status == "wait") {
          this.taskList["waiting"].push(taskObj);
        } else {
          this.taskList["notWaiting"].push(taskObj);
        }
        console.log(taskObj);
      }
      console.log(res.data);
    },
    //暂停/恢复任务
    async pauseOrRestore (item) {
      //console.log(item.isPauseBtn)
      item.btnLoading = true;
      item.btnDisable = true;
      if (!item.isPauseBtn) {
        await this.pauseProcess(item.id); //暂停
      } else {
        await this.continueProcess(item.id); //恢复
      }
      console.log("pause");
      item.btnDisable = false;
      item.btnLoading = false;
      let res = await this.getProcessById(item.id);
      item.status = res.status; //任务状态赋值
      for (let i = 0; i < res.childProcesses.length; i++) {
        item.childProcesses[i].status = res.childProcesses[i].status;
        item.childProcesses[i].tilesStatus = res.childProcesses[i].tilesStatus;
      }
      if (item.isPauseBtn) {
        //恢复后
        if (!processInterval) {
          //定时器未启动
          this.setIntervalProcess(); //启动定时器
        }
      }
    },
    //等待中的任务重新排序
    async orderWaitingTasks () {
      let param = [];
      for (let task of this.taskList["waiting"]) {
        param.push(task.id);
      }
      console.log(param);
      if (param.length > 0) {
        await this.setProcessPriority(param);
      }
    },
    async orthoRectificationHandle (param) {
      console.log(param);
      let res = await axios.post(`${Url}process${version}`, param);
      console.log(res.data);
      return res.data;
    },
    async getProcessById (id) {
      let res = await axios.post(
        `${Url}getStatusById${version}`,
        qs.stringify({ id: id })
      );
      console.log(res.data);
      return res.data;
    },
    async pauseProcess (id) {
      let res = await axios.post(
        `${Url}pauseProcess${version}`,
        qs.stringify({ id: id })
      );
      console.log(res.data);
      return res.data;
    },
    async continueProcess (id) {
      let res = await axios.post(
        `${Url}continueProcess${version}`,
        qs.stringify({ id: id })
      );
      console.log(res.data);
      return res.data;
    },
    async setProcessPriority (ids) {
      console.log(ids);
      let res = await axios.post(`${Url}setPriority${version}`, {
        id: ids
      });
      console.log(res.data);
      return res.data;
    },
    async getFinishedProcessResult () {
      let res = await axios.post(
        `${Url}listProcessResult${version}`,
        qs.stringify({
          orderId: this.passedOrderId
        })
      );
      console.log(res.data);
      //return res.data;
      //this.imageList = res.data;
      if (!(res.data instanceof Array) || res.data.length <= 0) {
        this.imageList = [];
        return;
      }
      for (let obj of res.data) {
        let extent = {
          "X-LT": 0,
          "Y-LT": 0,
          "X-RT": 0,
          "Y-RT": 0,
          "X-RB": 0,
          "Y-RB": 0,
          "X-LB": 0,
          "Y-LB": 0
        };
        if (obj.position.length == 0) {
          continue;
        }
        extent["X-LT"] = Number(obj.position[0].split(",")[0]);
        extent["Y-LT"] = Number(obj.position[0].split(",")[1]);
        extent["X-RT"] = Number(obj.position[1].split(",")[0]);
        extent["Y-RT"] = Number(obj.position[1].split(",")[1]);
        extent["X-RB"] = Number(obj.position[2].split(",")[0]);
        extent["Y-RB"] = Number(obj.position[2].split(",")[1]);
        extent["X-LB"] = Number(obj.position[3].split(",")[0]);
        extent["Y-LB"] = Number(obj.position[3].split(",")[1]);
        for (let imageName of obj.img) {
          let param = { id: obj.id };
          param["name"] = imageName;
          param["size"] = "";
          param["time"] = "";
          param["extent"] = JSON.stringify(extent);
          param["png_path"] = imageName;
          param["tilePath"] = obj.tilePath.replace("./", "") + "/" + imageName;
          this.imageList.push(param);
        }
      }
      console.log(this.imageList);
    },

    checkMove: function (e) {
      window.console.log("Future index: " + e.draggedContext.futureIndex);
    },
    //从子组件得到消息按钮距底部的距离
    getmMsgBtnBottom (value) {
      this.msgBtnBottom = value;
    },
    //从子组件得到上传文件的权限
    getFileUploadPermission (value) {
      this.showFileUploadBtn = value;
    }
  },
  watch: {
    //一旦有等待队列加入就排序
    taskList: {
      deep: true,
      handler (val) {
        console.log(val);
        if (val["waiting"].length > 0) {
          vm.$nextTick(() => {
            // createSortable("#waiting");
          });
        }
      }
    }
  }
};
/**
 * 使DIV元素可拖动
 */
function dragElement (elmnt) {
  var pos1 = 0,
    pos2 = 0,
    pos3 = 0,
    pos4 = 0;
  if (document.getElementById(elmnt.id + "-header")) {
    // 如果存在标题，则标题是您移动DIV的位置：
    document.getElementById(elmnt.id + "-header").onmousedown = dragMouseDown;
  } else {
    // 否则，请从DIV内部的任何位置移动DIV：
    elmnt.onmousedown = dragMouseDown;
  }

  function dragMouseDown (e) {
    e = e || window.event;
    e.preventDefault();
    // 获取启动时的鼠标光标位置：
    pos3 = e.clientX;
    pos4 = e.clientY;
    document.onmouseup = closeDragElement;
    // 每当光标移动时调用一个函数：
    document.onmousemove = elementDrag;
  }

  function elementDrag (e) {
    e = e || window.event;
    e.preventDefault();
    // 计算新的光标位置：
    pos1 = pos3 - e.clientX;
    pos2 = pos4 - e.clientY;
    pos3 = e.clientX;
    pos4 = e.clientY;
    // set the element's new position:
    elmnt.style.top = elmnt.offsetTop - pos2 + "px";
    elmnt.style.left = elmnt.offsetLeft - pos1 + "px";
  }

  function closeDragElement () {
    // stop moving when mouse button is released:
    document.onmouseup = null;
    document.onmousemove = null;
  }
  document.οndragstart = function () {
    return false;
  };
}
</script>
<style scoped>
/* 可以在选择器中使用/deep/或>>>来创建应用于子组件内部元素的样式规则 */
::v-deep .col-12 {
  padding-top: 0px;
  padding-bottom: 0px;
}

::v-deep .leaflet-pane {
  z-index: 0;
}

::v-deep .leaflet-top {
  z-index: 0;
}

.v-expansion-panel-content {
  overflow-y: auto;
}
::v-deep .leaflet-control-zoom {
  position: absolute;
  right: 11px;
  bottom: var(--leaflet-zoom-bottom);
}
::v-deep .leaflet-draw {
  position: absolute;
  right: 11px;
  bottom: var(--leaflet-draw-bottom);
}
.msg-btn-style {
  position: absolute;
  right: 18px;
}
.msg-panel-style {
  position: absolute;
  right: 65px;
}
.layer-bar-style {
  position: absolute;
  right: 20px;
  bottom: 25px;
}
.order-panel-style {
  position: absolute;
  top: 10px;
}
.satellite-map-switch {
  position: absolute;
  bottom: 10px;
}

::v-deep .leaflet-control-attribution {
  position: absolute;
  bottom: 0;
  right: 0px;
  width: max-content;
}
::v-deep .leaflet-control-attribution > a:first-child {
  display: none;
}
::v-deep .leaflet-control-scale {
  position: absolute;
  right: 314px;
  bottom: 0;
  margin-bottom: 0;
  margin-right: 0;
}
</style>
