<template>
        <div>
          <v-row no-gutters>
            <v-col cols="12" class="d-flex justify-center">
              <v-radio-group row v-model="selectedRadioGroupsIndex" dense
                :readonly="passedOrderId != -1 && !orderInfoBtn.showSubmitBtn">
                <v-radio v-for="(type, index) in conditionalSearchRadioGroups" :key="index" :label="type"
                  :value="index"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row class="px-4" no-gutters>
            <v-col cols="12">
              <v-text-field label="名称" v-model="orderName" dense :rules="[v => !!v || '名称不能为空']"
                :readonly="passedOrderId != -1"></v-text-field>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-if="selectedRadioGroupsIndex === 0" class="px-4">
            <v-col cols="12" md="4">
              <v-select label="省" dense :items="provinceOptions" return-object item-text="provinceName"
                item-value="provinceCode" v-model="selectedProvince" @change="changeSelectedProvince"
                :rules="[v => !!v || '省不能为空']" :readonly="
                  passedOrderId != -1 &&
                    !orderInfoBtn.showSubmitBtn &&
                    role != 'proUser'
                ">
                <template #label>
                  <span class="red--text"><strong>* </strong></span>省
                </template>
              </v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select label="市" dense :items="cityOptions" return-object item-text="cityName" item-value="cityCode"
                v-model="selectedCity" @change="getCountyItemsByCityCode" :readonly="
                  passedOrderId != -1 &&
                    !orderInfoBtn.showSubmitBtn &&
                    role != 'proUser'
                "></v-select>
            </v-col>
            <v-col cols="12" md="4">
              <v-select label="县" dense :items="countyOptions" return-object item-text="countyName"
                item-value="countyCode" v-model="selectedCounty" @change="changeSelectedCounty" :readonly="
                  passedOrderId != -1 &&
                    !orderInfoBtn.showSubmitBtn &&
                    role != 'proUser'
                "></v-select>
            </v-col>
            <v-col cols="2" offset="10" class="mt-n4 pa-0">
              <v-btn small text color="primary" @click="
                  orderInfoBtn.showRegionPolygonBtn = !orderInfoBtn.showRegionPolygonBtn;
                  orderInfoBtn.showRegionPolygonBtn
                    ? showCurrentCountyRegion()
                    : hideCountyPolygon();
                ">{{
                  orderInfoBtn.showRegionPolygonBtn ? "隐藏" : "显示"
                }}</v-btn>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" v-else-if="selectedRadioGroupsIndex === 1" no-gutters>
            <!-- <v-col cols="12" md="1"></v-col> -->
            <v-col cols="12" md="11">
              <v-textarea label="经纬度" no-resize rows="3" v-model="polygonCoordinates" outlined readonly class="pt-0"
                dense :rules="[v => !!v || '经纬度不能为空']"></v-textarea>
            </v-col>
            <v-col cols="2" offset="10" class="mt-n6" v-if="passedOrderId != -1">
              <v-btn small text color="primary" :disabled="coordPolygonBtnAble" @click="
                  showCoordPolygonBtn = !showCoordPolygonBtn;
                  isShowCoordPolygon();
                ">{{ showCoordPolygonBtn ? "隐藏" : "显示" }}</v-btn>
            </v-col>
          </v-row>
          <v-row align="center" justify="center" class="px-4">
            <v-col cols="12" md="6">
              <dateTimePicker label="捕获开始时间" :disabled="
                  passedOrderId != -1 &&
                    !orderInfoBtn.showSubmitBtn &&
                    role != 'proUser'
                " v-model="capturedStartTime"></dateTimePicker>
            </v-col>
            <v-col cols="12" md="6">
              <dateTimePicker label="捕获结束时间" :disabled="
                  passedOrderId != -1 &&
                    !orderInfoBtn.showSubmitBtn &&
                    role != 'proUser'
                " v-model="capturedEndTime"></dateTimePicker>
            </v-col>
          </v-row>
          <v-row align="center">
            <v-col cols="12" sm="3" class="pl-8 pb-2">服务</v-col>
            <v-col cols="12" sm="9">
              <v-radio-group row class="pa-0" dense v-model="selectedServiceTimeRadioIndex"
                :readonly="passedOrderId != -1 && !orderInfoBtn.showSubmitBtn">
                <v-radio label="一次"></v-radio>
                <v-radio label="多次"></v-radio>
              </v-radio-group>
            </v-col>
          </v-row>
          <v-row align="center" justify="center">
            <v-col cols="12" md="11">
              <v-textarea label="备注" no-resize rows="3" outlined class="pt-0" dense v-model="remark"
                :rules="[v => !!v || '备注不能为空']" :readonly="passedOrderId != -1 && !orderInfoBtn.showSubmitBtn">
              </v-textarea>
            </v-col>
          </v-row>

          <v-divider></v-divider>
          <!-- 动作按钮 -->
          <v-card-actions class="pt-4 d-flex justify-center">
            <v-btn color="primary" @click="submit()" v-if="orderInfoBtn.showSubmitBtn">提交</v-btn>

            <v-btn color="primary" v-if="passedOrderId != -1 && orderInfoBtn.showApprovalBtn"
              @click="updateApprovalStatus('approval')">审批</v-btn>

            <div v-if="passedOrderId != -1 && orderInfoBtn.showOfferBtn">
              <v-text-field dense solo prefix="￥" v-model="orderInfoBtn.orderPrice" type="number">
                <template v-slot:append-outer>
                  <v-btn color="primary" style="top: -5px" @click="updateOfferedStatus('offered')">报价</v-btn>
                </template>
              </v-text-field>
            </div>

            <v-btn color="warning" class="mr-2" v-if="passedOrderId != -1 && orderInfoBtn.showCancelBtn"
              @click="cancelOrder('canceled')">取消</v-btn>

            <div v-if="passedOrderId != -1 && orderInfoBtn.showSignContractBtn">
              <v-btn color="primary" @click="orderInfoBtn.showSignContractDialog = true">签约</v-btn>
              <v-dialog v-model="orderInfoBtn.showSignContractDialog" width="500">
                <v-card>
                  <v-card-title class="headline grey lighten-2">
                    隐私政策
                  </v-card-title>
                  <v-card-title>
                    项目金额：<span class="font-weight-bold">￥{{ orderInfoBtn.orderPrice }}</span>
                  </v-card-title>
                  <v-card-text>
                    协议是由用户（以下简称为“您”）与威海五洲卫星导航科技股份有限公司（以下称为“我们”）就我们所提供的产品和服务（包括我们的应用以及我们现在正在提供和将来可能向您提供的网络服务，以下统称为“产品和服务”）所订立的协议。如果您获得并使用我们所提供的产品和服务，则应视为您已经详细阅读了本协议的内容，同意本协议的内容，并同意遵守本协议的规定。为保障您的权益，请于注册、使用本公司所提供的各种服务之前，仔细阅读以下规定。一旦您使用我们的产品，即表示您同意并愿意遵守以下所有规定......
                  </v-card-text>
                  <v-divider></v-divider>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue-grey lighten-2" text @click="orderInfoBtn.showSignContractDialog = false">取消
                    </v-btn>
                    <v-btn color="primary" text @click="
                        orderInfoBtn.showSignContractDialog = false;
                        updateSignStatus('sign');
                      ">同意</v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

            <v-btn color="primary" class="mr-2" v-if="passedOrderId != -1 && orderInfoBtn.showPayedBtn"
              @click="payOrder('payed')">
              已付款</v-btn>

            <v-btn color="primary" class="mr-2" v-if="passedOrderId != -1 && orderInfoBtn.showMoneyArrivedBtn"
              @click="moneyArrived('arrived')">已到账</v-btn>

            <v-btn color="primary" class="mr-2" v-if="passedOrderId != -1 && orderInfoBtn.showFinishedBtn"
              @click="orderFinished('finished')">完成</v-btn>

            <v-btn color="success" @click="sceneSearchSubmit()" v-if="orderInfoBtn.showRetrievalBtn">检索</v-btn>
          </v-card-actions>
          <!-- 检索结果表格 -->
          <v-card class="overflow-y-auto ma-2" :max-height="role=='customer'?'40vh':'28vh'" v-if="originSceneList.length > 0">
            <v-text-field v-model="originSceneTableSearch" dense label="Search" class="mt-4 mx-4"></v-text-field>
            <v-data-iterator :items="originSceneList" :items-per-page.sync="itemsPerPage" :page="currentPage" 
              :search="originSceneTableSearch">
              <!-- <template v-slot:default="props">
              <v-row style="max-width:15vw">
                <v-col v-for="item in props.items" :key="item.id" cols="12" class="d-flex justify-center"> -->
              <template v-slot:item="itemObj">
                <v-card class="mb-1 mx-auto" max-width="300" @click.native="isSelectedScene(selectArray, itemObj.item)"
                  :style="
                    selectArray.indexOf(itemObj.item) > -1
                      ? 'transform: scale(0.95);'
                      : ''
                  ">
                  <v-list dense :color="
                      selectArray.indexOf(itemObj.item) > -1
                        ? 'blue-grey lighten-3'
                        : ''
                    ">
                    <v-list-item v-for="(obj, index) in getOriginScenesTableHeader()" :key="index">
                      <v-list-item-content>
                        {{ obj.text }}:
                      </v-list-item-content>
                      <v-list-item-content :style="{
                          'font-size':
                            obj.value == 'png_path' ? '10px' : '15px',
                          'word-break':
                            obj.value == 'png_path' ? 'break-all' : ''
                        }">
                        {{ itemObj.item[obj.value] }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </v-card>
              </template>
            </v-data-iterator>
          </v-card>
        </div>
</template>
<script>
import dateTimePicker from "../../components/DatetimePicker"; //日期时间组件
import L from "leaflet"; //leaflet地图obj
import axios from "axios"; //基于Promise的HTTP客户端
import commonCfg from "../../config/common";
import "leaflet-draw/dist/leaflet.draw";

let coordPolygon = null;
let countyPolygon = {};
let Url = commonCfg.commonUrl,
  version = commonCfg.urlVersion; // http://192.168.1.109:64011/
let vm;
export default {
  data () {
    return {
      orderName: "", //订单描述/名称
      provinceOptions: [], //省列表
      selectedProvince: "", //选中的省
      cityOptions: [], //城市列表
      selectedCity: "", //选中的市
      //countyList: [], //县列表
      countyOptions: [], //市下面的县列表
      selectedCounty: "", //选中的县
      capturedStartTime: "", //捕获开始时间
      capturedEndTime: "", //捕获结束时间
      polygonCoordinates: "", //在地图上画的多边形区域的坐标
      selectedServiceTimeRadioIndex: 0, //服务次数下标
      originSceneTableSearch: "", //scene表格搜索参数
      remark: "备注",
      originSceneList: [],
      // showSubmitBtn: false, //是否显示提交(新建/修改)订单的按钮
      selectArray: [],
      referenceCode: "", //邀请码
      //showMoneyArrivedBtn: false, //是否显示确认到账按钮
      showFileUploadBtn: false, //是否显示文件上传按钮
      selectedRadioGroupsIndex: -1, //默认下标
      itemsPerPage: 5, //每页默认显示条数
      currentPage: 1, //当前显示第几页
      coordPolygonBtnAble: false, //经纬度范围polygon的显示按钮是否可用
      showCoordPolygonBtn: true, //经纬度范围polygon 显示/隐藏按钮切换
      msgBtnBottomValue: "", //消息按钮距底部的距离
      role: "", //角色
      orderInfoBtn: {
        showSubmitBtn: false, //是否显示提交(新建/修改)订单的按钮
        showRetrievalBtn: false, //是否显示数据检索按钮
        showApprovalBtn: false, //是否显示审批按钮
        showOfferBtn: false, //是否显示报价按钮
        orderPrice: "", //订单价格
        showSignContractBtn: false, //是否显示签约按钮
        showSignContractDialog: false, //是否显示签约对话框
        showCancelBtn: false, //是否显示取消订单的按钮
        showPayedBtn: false, //是否显示已付款按钮
        showMoneyArrivedBtn: false, //是否显示确认到账按钮
        showFinishedBtn: false, // 是否显示订单完成按钮
        showRegionPolygonBtn: true //行政区 区域polygon 显示/隐藏按钮切换
      },
      submitAble: false //禁用数据检索按钮
    };
  },
  props: [
    //"satType",
    //"orderInfoBtn",
    "passedOrderId",
    //"showOrderPanel",
    "scenesSelectedArray",
    "leafMap",
    //"orderPanelLeft",
    "raster",
    "conditionalSearchRadioGroups"
    //"polygonCoordinates"
    // "showRegionPolygonBtn"
  ],
  components: {
    dateTimePicker
  },
  async created () {
    vm = this;
    //角色
    vm.role = vm.$root.role;
     
    this.selectArray = this.scenesSelectedArray;

    //编辑时显示订单详情
    if (this.passedOrderId != -1 && !isNaN(this.passedOrderId)) {
      let orderObj = await this.getOrderById(this.passedOrderId);
      this.showOrderInfo(orderObj);
      this.showPermissionBtn(orderObj);
    } else if (this.passedOrderId == -1) {
      //设置radio为默认类型
      this.selectedRadioGroupsIndex = 0;

      //普通用户和外部项目经理可以新建
      this.getProvinceList();
      if (this.role == "customer") {
        this.orderInfoBtn.showSubmitBtn = true;
      } else if (this.role == "projectMain") {
        await this.getReferenceCode();
        if (this.referenceCode != "1000")
          this.orderInfoBtn.showSubmitBtn = true;
      }
    }
  },
  mounted () {
    
    this.$nextTick(() => {
      vm.drawPolygon();
    });
  },

  methods: {
    //通过订单id获得订单信息
    async getOrderById (id) {
      let res = await axios.get(`${Url}getOrderById${version}`, {
        params: { id: id }
      });
      return res.data;
    },
    //获得邀请码
    async getReferenceCode () {
      let res = await axios.get(`${Url}getReferenceCode${version}`);
      console.log(res.data);
      this.referenceCode = res.data;
    },
    //显示相应权限按钮
    async showPermissionBtn (order) {
      //系统管理员
      if (this.role == "sysAdmin") {
        this.showFileUploadBtn = true; //上传文件
        this.$emit("passFileUploadPermission", this.showFileUploadBtn);

        if (order.status == "new") {
          this.orderInfoBtn.showSubmitBtn = true; //修改
        }
        //else if (order.status == "arrived") {
        //   this.showRetrievalBtn = true;//检索
        //   this.orderInfoBtn.showFinishedBtn = true;//完成按钮
        // }
      } else if (this.role == "proUser") {
        //专家
        this.showFileUploadBtn = true; //上传文件
        this.$emit("passFileUploadPermission", this.showFileUploadBtn);

        if (order.status == "arrived") {
          this.orderInfoBtn.showRetrievalBtn = true; //检索
          this.orderInfoBtn.showFinishedBtn = true; //完成按钮
        }
      } else if (this.role == "projectAdmin") {
        await this.getReferenceCode();
        if (this.referenceCode == "1000") {
          //内部项目管理员
          this.showFileUploadBtn = true; //上传文件
          this.$emit("passFileUploadPermission", this.showFileUploadBtn);

          if (order.status == "new") {
            this.orderInfoBtn.showApprovalBtn = true; //审批
          } else if (order.status == "approval") {
            this.orderInfoBtn.showOfferBtn = true; //报价
          } else if (order.status == "offered") {
            this.orderInfoBtn.showOfferBtn = true; //报价
            this.orderInfoBtn.orderPrice = order.price;
          } else if (order.status == "payed") {
            this.orderInfoBtn.showMoneyArrivedBtn = true; //钱到账
          }
        }
      }
      //用户本人才可以进行的操作
      if (order.username == localStorage.getItem("username")) {
        if (order.status == "new") {
          //用户取消订单前判断是否有未处理的订单修改申请
          let orderApplocation = await this.getOrderApplicationById(
            this.passedOrderId
          );
          if (orderApplocation) {
            return;
          }
          this.orderInfoBtn.showCancelBtn = true; //取消订单
        } else if (order.status == "approval") {
          this.orderInfoBtn.showCancelBtn = true; //取消订单
        } else if (order.status == "offered") {
          this.orderInfoBtn.showCancelBtn = true; //取消订单
          this.orderInfoBtn.orderPrice = order.price;
          this.orderInfoBtn.showSignContractBtn = true; //签约
        } else if (order.status == "sign") {
          this.orderInfoBtn.showPayedBtn = true; //已付款
        }
      }
    },
    //点击订单跳到详情页时 显示订单信息
    async showOrderInfo (order) {
      //订单详情
      this.orderName = order.name;
      this.capturedStartTime = new Date(order.capturedStartTime);
      this.capturedEndTime = new Date(order.capturedEndTime);
      order.servicedTime == "many"
        ? (this.selectedServiceTimeRadioIndex = 1)
        : (this.selectedServiceTimeRadioIndex = 0);
      this.remark = order.remark;
      let range = JSON.parse(order.range);
      this.selectedRadioGroupsIndex = range.index;
      if (range.index == 1) {
        this.polygonCoordinates = range.polygonCoord;
        this.showCoordinatesPolygon(this.polygonCoordinates);
        this.getProvinceList(); //省列表
      } else {
        await this.getProvinceList(); //省列表
        this.selectedProvince = range.province;
        await this.getCityList(); //省下面的市列表
        if (JSON.stringify(range.city) != "{}") {
          this.selectedCity = range.city;
          await this.getCountyList(); //市下面的县列表
          if (JSON.stringify(range.county) != "{}") {
            this.selectedCounty = range.county;
          }
        }
        if (this.selectedCounty) {
          //县不为"{}" 显示县区域
          this.getAdministrativeAreaCoords(
            this.selectedCounty.countyCode,
            "county"
          );
        } else if (this.selectedCity) {
          //市不为"{}" 显示市区域
          this.getAdministrativeAreaCoords(this.selectedCity.cityCode, "city");
        } else {
          //显示省
          this.getAdministrativeAreaCoords(
            this.selectedProvince.provinceCode,
            "province"
          );
        }
      }
    },
    //获得省列表
    async getProvinceList () {
      await axios
        .get(`${Url}getProvinceList${version}`)
        .then(res => {
          this.provinceOptions = res.data;
          console.log(this.provinceOptions);
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获得省下面的市列表
    async getCityList () {
      await axios
        .get(`${Url}getCityList${version}`, {
          params: { provinceCode: this.selectedProvince.provinceCode }
        })
        .then(res => {
          this.cityOptions = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //获得市下面的县列表
    async getCountyList () {
      await axios
        .get(`${Url}getCountyList${version}`, {
          params: { cityCode: this.selectedCity.cityCode }
        })
        .then(res => {
          this.countyOptions = res.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    //选择省
    changeSelectedProvince () {
      if (this.selectedCounty) {
        this.selectedCounty = "";
      }
      if (this.countyOptions.length > 0) {
        this.countyOptions = [];
      }
      if (!this.orderInfoBtn.showRegionPolygonBtn)
        this.orderInfoBtn.showRegionPolygonBtn = true;
      this.getAdministrativeAreaCoords(
        this.selectedProvince.provinceCode,
        "province"
      );

      this.getCityList();
    },
    //选择市
    getCountyItemsByCityCode () {
      if (this.selectedCounty) {
        this.selectedCounty = "";
      }
      if (!this.orderInfoBtn.showRegionPolygonBtn)
        this.orderInfoBtn.showRegionPolygonBtn = true;
      this.getAdministrativeAreaCoords(this.selectedCity.cityCode, "city");
      this.getCountyList();
    },
    //选择县
    changeSelectedCounty () {
      if (!this.orderInfoBtn.showRegionPolygonBtn)
        this.orderInfoBtn.showRegionPolygonBtn = true;
      this.getAdministrativeAreaCoords(
        this.selectedCounty.countyCode,
        "county"
      );
    },
    //获得省/市/县对应的坐标
    getAdministrativeAreaCoords (code, type) {
      axios
        .get(`${Url}getTextCoordinates${version}`, {
          params: { code: code, type: type }
        })
        .then(res => {
          vm.showAreaPolygon(res.data);
        })
        .catch(err => {
          console.log(err);
        });
    },
    //显示省/市/县区域
    showAreaPolygon (coordText) {
      //存在显示的区域，清空
      this.hideCountyPolygon();
      countyPolygon = {};
      //if (this.passedOrderId != -1) this.showRegionPolygonBtn = true;
      //多个polygon
      if (coordText.indexOf("MULTIPOLYGON") !== -1) {
        coordText = coordText.replace("MULTIPOLYGON(((", "");
        coordText = coordText.replace(")))", "");
        let coordRes = coordText.split(")),((");
        let maxPolygon,
          maxArrayLng = 0;
        countyPolygon["type"] = "MULTIPOLYGON";
        countyPolygon["value"] = [];
        for (let temp of coordRes) {
          let arr = [];
          let coords = temp.split(",");
          for (let coord of coords) {
            let lng = Number(
              coord.substring(coord.indexOf(" ") + 1, coord.length)
            );
            let lat = Number(coord.substring(0, coord.indexOf(" ")));
            //if(!isNaN(lng) && !isNaN(lat)){
            arr.push([lng, lat]);
            //}else{
            //console.log(coord)
            //}
          }
          let polygon = L.polygon(arr, { color: "#EC407A" }).addTo(vm.leafMap);
          countyPolygon["value"].push(polygon);
          if (arr.length > maxArrayLng) {
            maxArrayLng = arr.length;
            maxPolygon = polygon;
          }
        }
        // 缩放地图到合适的视野级别
        vm.leafMap.fitBounds(maxPolygon.getBounds());
        return;
      }
      countyPolygon["type"] = "POLYGON";
      //单个polygon
      coordText = coordText.replace("POLYGON((", "");
      coordText = coordText.replace("))", "");
      let coordRes = coordText.split(",");
      let arr = [];
      for (let coord of coordRes) {
        let lng = Number(coord.substring(coord.indexOf(" ") + 1, coord.length));
        let lat = Number(coord.substring(0, coord.indexOf(" ")));
        //if(!isNaN(lng) && !isNaN(lat)){
        arr.push([lng, lat]);
        //}else{
        //console.log(coord) 江苏 南京 数据有问题
        //}
      }
      let polygon = L.polygon(arr, { color: "#EC407A" }).addTo(vm.leafMap);
      countyPolygon["value"] = polygon;
      vm.leafMap.fitBounds(polygon.getBounds());
    },
    //清空当前的行政区范围polygon
    hideCountyPolygon () {
      if (countyPolygon["type"]) {
        if (countyPolygon["type"] == "MULTIPOLYGON") {
          for (let polygon of countyPolygon["value"])
            this.removeLayerFromMap(polygon);
        } else {
          this.removeLayerFromMap(countyPolygon["value"]);
        }
      }
    },
    //显示当前的行政区polygon
    showCurrentCountyRegion () {
      if (countyPolygon["type"]) {
        if (countyPolygon["type"] == "MULTIPOLYGON") {
          for (let polygon of countyPolygon["value"])
            vm.leafMap.addLayer(polygon);
        } else {
          vm.leafMap.addLayer(countyPolygon["value"]);
        }
      }
    },
    //显示经纬度多边形覆盖物
    showCoordinatesPolygon (coordinates) {
      let coordArr = coordinates.split(";");
      let latlngs = [];
      for (let coord of coordArr) {
        if (coord == "") continue;
        let arr = coord.split(",");
        latlngs.push([Number(arr[1]), Number(arr[0])]);
      }
      let polygon = L.polygon(latlngs, { color: "#EC407A" }).addTo(vm.leafMap);
      coordPolygon = polygon;
      //console.log(coordPolygon);
      // zoom the map to the polygon
      vm.leafMap.fitBounds(polygon.getBounds());
    },
    //操作判断结束后回到主页
    backMainAfterOperation () {
      setTimeout(() => {
        vm.backMain(0);
      }, 1500);
    },
    //回到主页
    backMain (selectedIndex) {
      this.$router.push({
        name: "Main",
        params: { selectedType: selectedIndex }
      });
    },
    //新建/修改订单
    async submit () {
      console.log(this.capturedStartTime, this.capturedEndTime);
      //非空判断
      if (
        !this.orderName ||
        !this.capturedStartTime ||
        !this.capturedEndTime ||
        !this.remark
      ) {
        return;
      }
      if (this.selectedRadioGroupsIndex == 0) {
        if (!this.selectedProvince) return; //省必选 市/县可选
        //if(!this.selectedCounty) return; //！！！！！！！后面修改
      } else {
        if (!this.polygonCoordinates) return;
      }
      //参数构造
      let param = {
        name: this.orderName,
        capturedStartTime: this.capturedStartTime.getTime(),
        capturedEndTime: this.capturedEndTime.getTime(),
        remark: this.remark
      };
      let range = { index: this.selectedRadioGroupsIndex };
      if (this.selectedRadioGroupsIndex == 0) {
        range["province"] = this.selectedProvince;
        if (this.selectedCity) {
          range["city"] = this.selectedCity;
        } else {
          range["city"] = {};
        }
        if (this.selectedCounty) {
          //range["county"] = {
          //  id: this.selectedCounty.properties_id,
          //  name: this.selectedCounty.properties_name
          //};
          range["county"] = this.selectedCounty;
        } else {
          range["county"] = {};
        }
      } else {
        range["polygonCoord"] = this.polygonCoordinates;
      }
      if (this.selectedServiceTimeRadioIndex == 0) {
        param["serviceTime"] = "one";
      } else {
        param["serviceTime"] = "many";
      }
      param["range"] = JSON.stringify(range);
      //新建订单
      if (this.passedOrderId == -1) {
        param["status"] = "new";
        param["createdTime"] = new Date().getTime();

        axios
          .post(`${Url}insertOrder${version}`, param)
          .then(async res => {
            console.log(res);
            if (res.data > -1) {
              await axios.post(`${Url}updateOrderViewTime${version}`, {
                viewTime: param["createdTime"],
                id: res.data
              });
              this.$emit("tips", "新建成功", "success");
            } else {
              this.$emit("tips", "新建失败", "error");
            }
            this.backMainAfterOperation();
          })
          .catch(err => {
            console.log(err);
            this.$emit("tips", "新建失败", "error");
          });
      } else {
        //订单修改申请提交前判断是否有未处理的订单修改申请
        let orderApplocation = await this.getOrderApplicationById(
          this.passedOrderId
        );
        if (orderApplocation) {
          this.$emit("tips", "有未处理的订单修改申请", "error");
          return;
        }
        //提交订单修改申请
        param["status"] = "new";
        param["orderId"] = this.passedOrderId;
        let orderObj = await this.getOrderById(this.passedOrderId);
        param["username"] = orderObj.username;
        param["applicationTime"] = new Date().getTime();

        this.$dialog
          .confirm({
            text: "提交修改申请后需要由用户同意或拒绝!",
            title: "确认提交？",
            cancel: "取消",
            actions: [
              {
                text: "取消",
                color: "blue",
                key: "false"
              },
              {
                text: "确定",
                color: "blue",
                key: true
              }
            ]
          })
          .then(async type => {
            if (!type) return;
            let res = await axios.post(
              `${Url}upsertOrderUpdatedApplication${version}`,
              param
            );
            if (res.data == "ok") {
              this.$emit("tips", "提交成功", "success");
            } else {
              this.$emit("tips", "提交失败", "error");
            }
            this.backMainAfterOperation();
          });
      }
      console.log(param);
    },
    //取消订单
    cancelOrder (status) {
      this.$dialog
        .confirm({
          text: "取消订单后将无法操作，请确认！",
          title: "确认取消？",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确定",
              color: "blue",
              key: true
            }
          ]
        })
        .then(async type => {
          if (!type) return;
          let res = await axios.post(`${Url}updateOrderStatus${version}`, {
            id: this.passedOrderId,
            status: status
          });
          if (res.data == "ok") {
            this.$emit("tips", "取消成功", "success");
          } else {
            this.$emit("tips", "取消失败", "error");
          }
          this.backMainAfterOperation();
        });
    },
    //审批订单
    async updateApprovalStatus (status) {
      //超出一定时间未审批
      let orderObj = await this.getOrderById(this.passedOrderId);
      if (
        new Date().getTime() - orderObj.createdTime >
        15 * 24 * 60 * 60 * 1000
      ) {
        await axios.post(`${Url}updateOrderStatus${version}`, {
          id: this.passedOrderId,
          status: "canceled"
        });
        this.$emit("tips", "订单因长时间未处理已被取消", "error");
        this.backMainAfterOperation();
        return;
      }
      //审批前判断是否有未处理的订单修改申请
      let orderApplocation = await this.getOrderApplicationById(
        this.passedOrderId
      );
      if (orderApplocation) {
        this.$emit("tips", "有未处理的订单修改申请", "error");
        return;
      }
      this.$dialog
        .confirm({
          text: "请确认订单信息后再进行审批！",
          title: "确认审批？",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确定",
              color: "blue",
              key: true
            }
          ]
        })
        .then(async type => {
          if (!type) return;
          let res = await axios.post(`${Url}updateOrderStatus${version}`, {
            id: this.passedOrderId,
            status: status
          });
          if (res.data == "canceled") {
            this.$emit("tips", "订单已被取消，审批失败", "error");
            this.orderInfoBtn.showApprovalBtn = false;
          } else if (res.data == "ok") {
            this.$emit("tips", "审批成功", "success");
          } else {
            this.$emit("tips", "审批失败", "error");
          }
          this.backMainAfterOperation();
        });
    },
    //订单报价
    async updateOfferedStatus (status) {
      if (!this.orderInfoBtn.orderPrice) {
        this.$emit("tips", "请输入订单报价", "error");
        return;
      }
      let res = await axios.post(`${Url}updateOrderStatus${version}`, {
        id: this.passedOrderId,
        status: status,
        price: this.orderInfoBtn.orderPrice
      });
      if (res.data == "canceled") {
        this.$emit("tips", "订单已被取消，报价失败", "error");
      } else if (res.data == "ok") {
        this.$emit("tips", "报价成功", "success");
        //报价后判断用户是否签约 一定时间内未签约则自动取消
        axios.get(`${Url}isSigned${version}`, {
          params: { orderId: this.passedOrderId }
        });
      } else {
        this.$emit("tips", "报价失败", "error");
      }
      this.backMainAfterOperation();
    },
    //合同 签约
    async updateSignStatus (status) {
      let res = await axios.post(`${Url}updateOrderStatus${version}`, {
        id: this.passedOrderId,
        status: status
      });
      if (res.data == "canceled") {
        this.$emit("tips", "订单因超时已被自动取消", "error");
      } else if (res.data == "ok") {
        this.$emit("tips", "签约成功", "success");
      } else {
        this.$emit("tips", "签约失败", "error");
      }
      this.backMainAfterOperation();
    },

    //客户 已付款
    payOrder (status) {
      this.$dialog
        .confirm({
          text: "请确认已经打款后再进行此操作！",
          title: "确认已付款？",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确定",
              color: "blue",
              key: true
            }
          ]
        })
        .then(async type => {
          if (!type) return;
          let res = await axios.post(`${Url}updateOrderStatus${version}`, {
            id: this.passedOrderId,
            status: status
          });
          if (res.data == "ok") {
            this.$emit("tips", "操作成功", "success");
          } else {
            this.$emit("tips", "操作失败", "error");
          }
          this.backMainAfterOperation();
        });
    },
    //内部项目管理员 钱到账
    moneyArrived (status) {
      this.$dialog
        .confirm({
          text:
            "请确认钱已到账后再进行此操作！确认成功后会将此订单分配给专业人员进行处理。",
          title: "确认已到账？",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确定",
              color: "blue",
              key: true
            }
          ]
        })
        .then(async type => {
          if (!type) return;
          let res = await axios.post(`${Url}updateOrderStatus${version}`, {
            id: this.passedOrderId,
            status: status
          });
          if (res.data == "ok") {
            this.$emit("tips", "确认成功", "success");
          } else {
            this.$emit("tips", "确认失败", "error");
          }
          this.backMainAfterOperation();
        });
    },
    //订单完成
    orderFinished (status) {
      this.$dialog
        .confirm({
          text: "请确认订单完成后再进行此操作！确认后不可更改。",
          title: "确认完成？",
          cancel: "取消",
          actions: [
            {
              text: "取消",
              color: "blue",
              key: "false"
            },
            {
              text: "确定",
              color: "blue",
              key: true
            }
          ]
        })
        .then(async type => {
          if (!type) return;
          let res = await axios.post(`${Url}updateOrderStatus${version}`, {
            id: this.passedOrderId,
            status: status
          });
          if (res.data == "ok") {
            this.$emit("tips", "操作成功", "success");
          } else {
            this.$emit("tips", "操作失败", "error");
          }
          this.backMainAfterOperation();
        });
    },

    //通过订单id获得订单修改申请
    async getOrderApplicationById (orderId) {
      let res = await axios.get(`${Url}getOrderApplicationById${version}`, {
        params: { orderId: orderId }
      });
      return res.data;
    },
    //根据订单条件检索出的表格数据 标题
    getOriginScenesTableHeader () {
      return [
        {
          // 显示的表格列
          align: "left",
          text: "传感器ID",
          value: "sensor_id"
        },
        {
          align: "center",
          text: "卫星ID",
          value: "sat_id"
        },
        {
          align: "left",
          text: "像素大小",
          value: "pixel_size"
        },
        {
          align: "left",
          text: "云覆盖",
          value: "cloud_cover"
        },
        {
          align: "center",
          text: "捕获时间",
          value: "sensed_time"
        },
        {
          align: "center",
          text: "文件名",
          value: "png_path"
        }
      ];
    },
    //选中/取消一条scene记录 从数组添加/删除元素
    isSelectedScene (array, item) {
      let index = array.indexOf(item);
      if (index > -1) {
        array.splice(index, 1);

        this.removeLayerFromMap(item["imageLayer"]);
      } else {
        array.push(item);
        // this.satType = item.sat_id;
        this.$emit("changeSatType", item.sat_id);
        this.showTileLayer("origin", item);
      }
      console.log(array);
      this.$emit("setScenesSelectedArray", array);
    },
    async showTileLayer (flag, item, childProcessIndex) {
      let path = "";
      if (flag != "childTile") {
        if (flag == "origin") {
          path = `scenes/showData/${item.png_path}`;
        } else if (flag == "finishedImg") {
          let end = item.name.substring(item.name.lastIndexOf(".") + 1);
          if (end == "png") {
            let imageBounds = [
              [22.904617, 113.21201],
              [22.601074, 113.53823]
            ];
            let path =
              process.env.BASE_URL +
              item.tilePath.substring(0, item.tilePath.lastIndexOf("-")) +
              "/" +
              item.name;
            this.png = path;
            this.click_img = item.tilePath;
            this.restorePng = this.png;
            console.log(path);
            item["opacity"] = 1;
            this.raster.layer = L.imageOverlay(path, imageBounds, {
              opacity: item["opacity"],
              interactive: true
            }).addTo(vm.leafMap);

            this.raster.latLngs = imageBounds;
            item["imageLayer"] = this.raster.layer;
            // add the image overlay,
            // so that it covers the entire map
            // L.imageOverlay(url, bounds).addTo(vm.leafMap);
            // tell leaflet that the map is exactly as big as the image
            vm.leafMap.fitBounds(imageBounds);
            return;
          }
          path = item.tilePath.substring(0, item.tilePath.lastIndexOf("."));
        }
        // X: 经度 Y: 纬度
        //leaflet  if the area crosses the antimeridian, you must specify corners outside the [-180, 180] degrees longitude range.
        let extent = JSON.parse(item.extent);
        let xLT = extent["X-LT"];
        let xRB = extent["X-RB"];
        let LTCoordArray = [extent["Y-LT"], xLT];
        let RBCoordArray = [extent["Y-RB"], xRB];
        let imageBounds = [LTCoordArray, RBCoordArray];
        vm.leafMap.fitBounds(imageBounds);
      } else {
        //子任务 切片显示
        let res = await this.getProcessById(item.id);
        path = res.childProcesses[childProcessIndex].tilesPath;
        if (path) {
          path = path.substring(2, path.length);
          //path = path.replace("showData", "scenes")
        }
      }
      item["opacity"] = 1;
      let layer = L.tileLayer(
        process.env.BASE_URL + `${path}/{z}/{x}/{y}.png`, //https://dev.navi-tech.net/rstp/statics/scenes/rst-admin/222/GF2_PMS1_E113.4_N22.8_20150123_L1A0000607625-MSS1_3 file:///E:/TileDirectory
        {
          tms: true,
          opacity: item["opacity"],
          attribution: "",
          minZoom: 1,
          maxZoom: 18
        }
      ).addTo(vm.leafMap);
      console.log(process.env.BASE_URL, layer);
      if (flag != "childTile") item["imageLayer"] = layer;
    },
    //从地图上删除scene layer
    removeLayerFromMap (layer) {
      if (layer) {
        vm.leafMap.removeLayer(layer);
      }
      //item["imageLayer"] = null
    },
    //根据搜索条件获得对应的scene列表
    async sceneSearchSubmit () {
      this.originSceneList = [];
      this.selectArray = [];
      this.submitAble = true;
      setTimeout(() => {
        this.submitAble = false;
      }, 5000);

      let param = {
        sensedStartTime: commonCfg.dateToString(this.capturedStartTime),
        sensedEndTime: commonCfg.dateToString(this.capturedEndTime)
      };
      if (this.selectedRadioGroupsIndex === 0) {
        param["selectedType"] = "area";
        if (this.selectedCounty) {
          param["administrativeType"] = "county";
          param["code"] = this.selectedCounty.countyCode;
        } else if (this.selectedCity) {
          param["administrativeType"] = "city";
          param["code"] = this.selectedCity.cityCode;
        } else {
          param["administrativeType"] = "province";
          param["code"] = this.selectedProvince.provinceCode;
        }
      } else if (this.selectedRadioGroupsIndex === 1) {
        param["selectedType"] = "coordinates";
        param["coordinates"] = this.polygonCoordinates;
      }
      console.log(param);
      let res = await axios.post(`${Url}getSceneList${version}`, param);
      console.log(res.data);
      try {
        if (!res.data.length) {
          this.originSceneList = [];
          this.$emit("tips", "暂无结果", "error");
          return;
        }
        for (let data of res.data) {
          data["sensed_time"] = commonCfg.dateToString(
            new Date(data["sensed_time"])
          );
        }
        this.originSceneList = res.data;
      } catch (error) {
        console.log(error);
        this.originSceneList = [];
      }
    },

    //在地图上绘制多边形区域
    drawPolygon () {
      // Initialise the FeatureGroup to store editable layers
      var editableLayers = new L.FeatureGroup();
      vm.leafMap.addLayer(editableLayers);

      var options = {
        position: "bottomright",
        draw: {
          polygon: {
            allowIntersection: false, // Restricts shapes to simple polygons
            drawError: {
              color: "#e1e100", // Color the shape will turn when intersects
              message: "<strong>Oh snap!<strong> you can't draw that!" // Message that will show when intersect
            },
            shapeOptions: {
              color: "#9C27B0"
            }
          },

          // disable toolbar item by setting it to false
          polyline: false,
          circle: false, // Turns off this drawing tool
          marker: false,
          rectangle: false,
          circlemarker: false
        },
        edit: {
          featureGroup: editableLayers //REQUIRED!!
        }
      };

      // Initialise the draw control and pass it the FeatureGroup of editable layers
      var drawControl = new L.Control.Draw(options);
      vm.leafMap.addControl(drawControl);

      //add CSS class to Leaflet Control
      L.DomUtil.addClass(drawControl._container, "coordinates-polygon");
      //创建时 触发
      vm.leafMap.on("draw:created", function (e) {
        editableLayers.clearLayers();
        editableLayers.addLayer(e.layer);
        //console.log(e.layer._latlngs);
        vm.polygonCoordinates = "";
        for (let latlng of e.layer._latlngs[0]) {
          let lng =
            latlng.lng >= -360 && latlng.lng <= -180
              ? latlng.lng + 360
              : latlng.lng >= 180 && latlng.lng <= 360
                ? latlng.lng - 360
                : latlng.lng;
          vm.polygonCoordinates =
            vm.polygonCoordinates + `${lng},${latlng.lat};`;
        }
        //一旦进行经纬度的重新绘制 禁用原来经纬度的显示/隐藏按钮
        if (vm.passedOrderId != -1) {
          vm.coordPolygonBtnAble = true;
          vm.removeLayerFromMap(coordPolygon);
        }
        console.log(vm.polygonCoordinates);
      });
      //编辑时 点击save 触发
      vm.leafMap.on("draw:edited", function (e) {
        let coord = "";
        for (let key in e.layers._layers) {
          for (let latlng of e.layers._layers[key]["_latlngs"][0]) {
            let lng =
              latlng.lng >= -360 && latlng.lng <= -180
                ? latlng.lng + 360
                : latlng.lng >= 180 && latlng.lng <= 360
                  ? latlng.lng - 360
                  : latlng.lng;
            coord = coord + `${lng},${latlng.lat};`;
          }
        }
        if (coord) vm.polygonCoordinates = coord;
      });
      //删除时 点击save clearAll 触发
      vm.leafMap.on("draw:deleted", function (e) {
        let coord = "";
        for (let key in e.layers._layers) {
          for (let latlng of e.layers._layers[key]["_latlngs"][0]) {
            let lng =
              latlng.lng >= -360 && latlng.lng <= -180
                ? latlng.lng + 360
                : latlng.lng >= 180 && latlng.lng <= 360
                  ? latlng.lng - 360
                  : latlng.lng;
            coord = coord + `${lng},${latlng.lat};`;
          }
        }
        if (coord) vm.polygonCoordinates = "";
      });
    },
    //leaflet control 控件 设置距bottom的距离
    setLeafletDrawBottom (value) {
      document.documentElement.style.setProperty(
        "--leaflet-draw-bottom",
        `${value}px`
      );
    },
    //订单所选范围是经纬度 控制经纬度layer的显示/隐藏
    isShowCoordPolygon () {
      if (this.showCoordPolygonBtn) {
        vm.leafMap.addLayer(coordPolygon);
      } else {
        this.removeLayerFromMap(coordPolygon);
      }
    }
  },
  watch: {
    //控制多边形画图工具的显示和隐藏
    selectedRadioGroupsIndex: {
      handler (val) {
        this.$nextTick(() => {
          let divs = document.getElementsByClassName("coordinates-polygon");
          if (val === 1) {
            //经纬度类型
            if (!vm.orderInfoBtn.showSubmitBtn) {
              for (let i = 0; i < divs.length; i++) {
                divs[i].style.display = "none";
              }
              vm.msgBtnBottomValue = 135;
              vm.$emit("passMsgBtnBottom", vm.msgBtnBottomValue);
              return;
            }
            //显示画图工具
            vm.msgBtnBottomValue = 250;
            vm.setLeafletDrawBottom(120);
            for (let i = 0; i < divs.length; i++) {
              divs[i].style.display = "block";
            }
          } else {
            //行政区类型
            vm.msgBtnBottomValue = 135;
            for (let i = 0; i < divs.length; i++) {
              divs[i].style.display = "none";
            }
          }
          //传递消息按钮距离底部的距离给父组件
          vm.$emit("passMsgBtnBottom", vm.msgBtnBottomValue);
        });
      }
    }
  }
};
</script>
