module.exports = {
  mapBoxAccessToken:
    "pk.eyJ1Ijoic2FpamludGFvIiwiYSI6ImNrZXh2dHQyNjA1NGczMHBobnQ0bDM0ZGMifQ.dAO3yCa49vke7FVK7Saldw", //mapbox access token
  zoom: 13, //地图初始缩放级别
  center: [37.51, -237.883], //地图初始中心点 51.505, -0.09
  style: "mapbox/streets-v11", //地图样式
  maxZoom: 18, //最大缩放级别
  tileSize: 512, //瓦片数量 默认512×512像素
  // cityOptions: [
  //   { name: "济南", id: 3701 },
  //   { name: "青岛", id: 3702 },
  //   { name: "淄博", id: 3703 },
  //   { name: "枣庄", id: 3704 },
  //   { name: "东营", id: 3705 },
  //   { name: "烟台", id: 3706 },
  //   { name: "潍坊", id: 3707 },
  //   { name: "济宁", id: 3708 },
  //   { name: "泰安", id: 3709 },
  //   { name: "威海", id: 3710 },
  //   { name: "日照", id: 3711 },
  //   { name: "莱芜", id: 3712 },
  //   { name: "临沂", id: 3713 },
  //   { name: "德州", id: 3714 },
  //   { name: "聊城", id: 3715 },
  //   { name: "滨州", id: 3716 },
  //   { name: "菏泽", id: 3717 }
  // ]
};
